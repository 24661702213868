import { Box, Checkbox, Container, FormControlLabel, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Styles.module.scss";
import { DataContext } from "../../App";
// import { validateForgotPassword, validateSignIn } from "../Handle";
import axios from "../../axios";
import Auth from "../../Auth";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { VscArrowSmallRight } from "react-icons/vsc";
import { Bars } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function StoreLogin() {
	const [loginButtonDisabled, setLoginButtonDisabled] = React.useState(false);
	const { setAuth, setSnackbar } = useContext(DataContext);
	const { t } = useTranslation();

	const handleLogin = () => {
		setLoginButtonDisabled(true);
		axios
			.post("/exact/store/login", inputValue)
			.then((response) => {
				setLoginButtonDisabled(false);

				setAuth({ currentUser: response.data.user });
				Auth.setUser(response.data.user);
				Auth.setToken(response.data.token.plainTextToken);

				if (response.data.user.store_division == null) {
					window.location.href = "/try-now";
				} else {
					window.location.href = "/";
				}
			})
			.catch(({ response }) => {
				setLoginButtonDisabled(false);

				setSnackbar({
					message: t(response.data?.message ?? "Server error."),
					severity: "error",
					open: true,
				});
			});
	};

	const [inputValue, setInputValue] = useState({
		email: "",
		password: "",
		first_name: "",
		last_name: "",
		company_name: "",
	});

	const [touched, setTouched] = useState({});

	const handleChange = (event) => {
		setInputValue((prevState) => ({
			...prevState,
			[event.target.id]:
				event.target.type === "checkbox"
					? event.target.checked
					: event.target.value,
		}));
	};

	useEffect(() => {
		// setErrors(validateSignIn(inputValue));
	}, [inputValue, touched]);

	const focusHandler = (event) => {
		setTouched({ ...touched, [event.target.id]: true });
	};

	const [loginVisiblity, setShowLogin] = useState(true);

	return (
		<Container sx={{ marginTop: 4, marginBottom: 2, width: "100%" }}>
			<Grid
				container
				sx={{ display: "flex", justifyContent: "center", width: "100%" }}
			>
				<Grid
					container
					item
					sm={10}
					sx={{ display: "flex", justifyContent: "center", width: "100%" }}
				>
					<Box>
						<Box sx={{ width: "100%" }}>
							<Grid
								container
								item
								sm={10}
								sx={{
									display: "flex",
									justifyContent: "center",
									width: "100%",
								}}
							>
								<Box>
									<Box sx={{ width: "100%" }}>
										<Step1
											inputValue={inputValue}
											focusHandler={focusHandler}
											handleChange={handleChange}
											// errors={errors}
											touched={touched}
											handleLogin={handleLogin}
										/>
									</Box>
								</Box>
								<React.Fragment>
									<Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
										<Button onClick={() => {
											setShowLogin((prevState) => !prevState);
										}}

										>
											Forgot Password?
										</Button>
										<Box sx={{ flex: "1 1 auto" }} />
										<Button onClick={handleLogin} disabled={loginButtonDisabled}

										>
											Login
										</Button>
									</Box>
								</React.Fragment>
							</Grid>


						</Box>
					</Box>
				</Grid>
			</Grid>
		</Container>
	);
}

export function Step1({
	inputValue,
	focusHandler,
	handleChange,
	handleLogin,
	errors,
	touched,
}) {
	return (
		<>
			<div className={styles.inputs} style={{ marginBottom: "13px" }}>
				<div>
					<label htmlFor="email">E-mail *</label>
				</div>
				<input
					defaultValue={inputValue.email}
					onChange={handleChange}
					onFocus={focusHandler}
					type="email"
					id="email"
				/>
				<div>{errors?.email && touched.email && <p>{errors.email}</p>}</div>
			</div>
			<div className={styles.inputs} style={{ marginBottom: "13px" }}>
				<div>
					<label htmlFor="password">Password *</label>
				</div>
				<input
					defaultValue={inputValue.password}
					onChange={handleChange}
					onFocus={focusHandler}
					type="password"
					id="password"
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							handleLogin();
						}
					}}
				/>
				<div>
					{errors?.password && touched.password && <p>{errors.password}</p>}
				</div>
			</div>
		</>
	);
}

export const validateForgotPassword = (data, t) => {
	const errors = {};

	if (!data.email) {
		errors.email = t("Email Required");
	} else if (!/\S+@\S+\.\S+/.test(data.email)) {
		errors.email = t("Email Address Is Invalid");
	} else {
		delete errors.email;
	}

	return errors;
};

export const validateSignIn = (data, t) => {
	const errors = {};

	if (!data.email) {
		errors.email = t("Email Required");
	} else if (!/\S+@\S+\.\S+/.test(data.email)) {
		errors.email = t("Email Address Is Invalid");
	} else {
		delete errors.email;
	}

	if (!data.password) {
		errors.password = t("Password Required.");
	} else {
		delete errors.password;
	}

	return errors;
};

const Login = (props) => {
	const { setAuth } = useContext(DataContext);
	const { setSnackbar } = useContext(DataContext);
	const [inputValue, setInputValue] = useState({
		email: "",
		password: ""
	});
	const { t } = useTranslation();


	const [loginButton, setLoginButton] = useState({
		children: (
			<>
				{t("LOG IN")} <VscArrowSmallRight />
			</>
		),
		status: false,
	});
	const [errors, setErrors] = useState({});
	const [touched, setTouched] = useState({});

	const handleChange = (event) => {
		setInputValue((prevState) => ({
			...prevState,
			[event.target.id]:
				event.target.type === "checkbox"
					? event.target.checked
					: event.target.value,
		}));
	};

	useEffect(() => {
		setErrors(validateSignIn(inputValue, t));
	}, [inputValue, touched]);

	const focusHandler = (event) => {
		setTouched({ ...touched, [event.target.id]: true });
	};

	const loginUser = async () => {
		if (!Object.keys(errors).length) {
			setLoginButton({
				children: <Bars height="20" width="80" color="var(--primary)" />,
				status: true,
			});


			axios
				.post("/exact/store/login", {
					email: inputValue.email,
					password: inputValue.password,
				})
				.then((response) => {
					setAuth({ currentUser: response.data.user });
					Auth.setUser(response.data.user);
					Auth.setToken(response.data.token.plainTextToken);

					if (response.data.user.store_division == null) {
						window.location.href = "/try-now";
					} else {
						window.location.href = "/";
					}
				})
				.catch(({ response }) => {
					setSnackbar({
						message: t(response.data.message),
						severity: "error",
						open: true,
					});

					setLoginButton({
						children: (
							<>
								{t("LOG IN")} <VscArrowSmallRight />
							</>
						),
						status: false,
					});
				});
		} else {
			setTouched({
				email: true,
				password: true
			});
		}
	};

	return (
		<>
			<div className={styles.inputs} style={{ marginBottom: "13px" }}>
				<div>
					<label htmlFor="email">{t("E-mail")} *</label>
				</div>
				<input
					defaultValue={inputValue.email}
					onChange={handleChange}
					onFocus={focusHandler}
					type="text"
					id="email"
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							loginUser();
						}
					}}
				/>
				<div>{errors.email && touched.email && <p>{errors.email}</p>}</div>
			</div>
			<div className={styles.inputs} style={{ marginBottom: "13px" }}>
				<div>
					<label htmlFor="password">{t("Password")} *</label>
				</div>
				<input
					defaultValue={inputValue.password}
					onChange={handleChange}
					onFocus={focusHandler}
					type="password"
					id="password"
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							loginUser();
						}
					}}
				/>
				<div>
					{errors.password && touched.password && <p>{errors.password}</p>}
				</div>
			</div>

			<div className={styles.login}>
				<button
					disabled={loginButton.status}
					className={loginButton.status ? styles.none : ""}
					onClick={loginUser}
				>
					{loginButton.children}
				</button>
			</div>
		</>
	);
};

const ForgotPassword = (props) => {
	const { setSnackbar } = useContext(DataContext);
	const [inputValue, setInputValue] = useState({
		email: "",
	});
	const { t } = useTranslation();

	const [loginButton, setLoginButton] = useState({
		children: (
			<>
				{t("SEND EMAIL")} <VscArrowSmallRight />
			</>
		),
		status: false,
	});
	const [errors, setErrors] = useState({});
	const [touched, setTouched] = useState({});

	const handleChange = (event) => {
		setInputValue((prevState) => ({
			...prevState,
			[event.target.id]: event.target.value,
		}));
	};

	useEffect(() => {
		setErrors(validateForgotPassword(inputValue, t));
	}, [inputValue, touched]);

	const focusHandler = (event) => {
		setTouched({ ...touched, [event.target.id]: true });
	};

	const sendPasswordResetLink = async () => {
		if (!Object.keys(errors).length) {
			setLoginButton({
				children: <Bars height="20" width="80" color="var(--primary)" />,
				status: true,
			});

			axios
				.post("/exact/store/forgot-password", {
					email: inputValue.email,
				})
				.then((response) => {
					setSnackbar({
						message: t("Password reset link successfully sent."),
						severity: "success",
						open: true,
					});

					setLoginButton({
						children: (
							<>
								{t("SEND EMAIL")} <VscArrowSmallRight />
							</>
						),
						status: false,
					});
				})
				.catch(({ response }) => {
					setSnackbar({
						message: t(response.data.message),
						severity: "error",
						open: true,
					});

					setLoginButton({
						children: (
							<>
								{t("SEND EMAIL")} <VscArrowSmallRight />
							</>
						),
						status: false,
					});
				});
		} else {
			setTouched({
				email: true,
			});
		}
	};

	return (
		<>
			<div className={styles.inputs} style={{ marginBottom: "13px" }}>
				<div>
					<label htmlFor="email">{t("E-mail")} *</label>
				</div>
				<input
					defaultValue={inputValue.email}
					onChange={handleChange}
					onFocus={focusHandler}
					type="text"
					id="email"
				/>
				<div>{errors.email && touched.email && <p>{errors.email}</p>}</div>
			</div>
			<div className={styles.login}>
				<button
					disabled={loginButton.status}
					className={loginButton.status ? styles.none : ""}
					onClick={sendPasswordResetLink}
				>
					{loginButton.children}
				</button>
			</div>
		</>
	);
};

const SignIn = (props) => {
	const [loginVisiblity, setShowLogin] = useState(true);
	const { t } = useTranslation();

	return (
		<Container sx={{ marginTop: 4, marginBottom: 2, width: "100%" }}>
			<Grid
				container
				sx={{ display: "flex", justifyContent: "center", width: "100%" }}
			>
				<Grid
					container
					item
					sm={8}
					sx={{ display: "flex", justifyContent: "center", width: "100%" }}
				><Box>
						{loginVisiblity ? (
							<Login onSuccess={props.onSuccess} />
						) : (
							<ForgotPassword />
						)}

						<hr style={{ margin: "2rem 0" }} />
						<div
							className={styles.bottom}
							style={{
								textAlign: "center",
							}}
						>
							<Link
								component={"button"}
								onClick={() => {
									setShowLogin((prevState) => !prevState);
								}}
							>
								{loginVisiblity ? t("Forgot password?") : t("Back to login")}
							</Link>
						</div>
					</Box>
				</Grid>
			</Grid>
		</Container>);

};

export default SignIn;