import React from "react";
import {
  Grid,
  FormControl,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  Link,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Addresses({
  ordered,
  delivery,
  invoice,
  handleChange,
  inputValue,
  handleOpenAddressDialog,
}) {
  const formatAddress = (address, type) => {
    let _address = address.AddressLine1;

    if (address.AddressLine2) {
      _address += ", " + address.AddressLine2;
    }
    if (address.AddressLine3) {
      _address += ", " + address.AddressLine3;
    }
    if (address.PostCode) {
      _address += ", " + address.PostCode;
    }

    if (address.City) {
      _address += ", " + address.City;
    }

    if (address.Country) {
      _address += " " + address.Country;
    }
    _address = (
      <>
        {_address}&nbsp;
        <Link
          onClick={handleOpenAddressDialog}
          address-type={type}
          id={address.ID}
        >
          Edit
        </Link>
      </>
    );

    return _address;
  };

  const { t } = useTranslation();

  return (
    <Grid
      container
      item
      spacing={1}
      sx={{ paddingLeft: "0px !important", paddingRight: "0px !important" }}
    >
      {" "}
      <Grid item xs={4}>
        <FormControl fullWidth required>
          <FormLabel>{t("Ordered By Addresses")}</FormLabel>
          <FormControl fullWidth sx={{ marginTop: 1 }}>
            <InputLabel id="ordered-by-address-label">{t("Address")}</InputLabel>
            <Select
              labelId="ordered-by-address-label"
              label={t("Select")}
              size="sm"
              onChange={(e) => {
                handleChange(
                  {
                    target: {
                      id: "ordered_by_address",
                      value: e.target.value,
                    },
                  },
                  "main"
                );
              }}
              fullWidth
              value={inputValue.main.ordered_by_address ?? ''}
            >
              {ordered.map((address) => {
                return (
                  <MenuItem
                    value={address.ID}
                    key={address.ID}
                    address-type="ordered_by"
                  >
                    {formatAddress(address, "ordered_by")}
                  </MenuItem>
                );
              })}
              <MenuItem
                onClick={handleOpenAddressDialog}
                address-type="ordered_by"
              >
                {t("Add new")}
              </MenuItem>
            </Select>
          </FormControl>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth required>
          <FormLabel>{t("Delivery Addresses")}</FormLabel>
          <FormControl fullWidth sx={{ marginTop: 1 }}>
            <InputLabel id="delivery-address-label">{t("Addresses")}</InputLabel>
            <Select
              labelId="delivery-address-label"
              label={t("Select")}
              size="sm"
              fullWidth
              onChange={(e) => {
                handleChange(
                  {
                    target: {
                      id: "delivery_address",
                      value: e.target.value,
                    },
                  },
                  "main"
                );
              }}
              value={inputValue.main.delivery_address ?? ''}
            >
              {delivery.map((address) => {
                return (
                  <MenuItem
                    value={address.ID}
                    key={address.ID}
                    address-type="delivery_to"
                  >
                    {formatAddress(address, "delivery_to")}
                  </MenuItem>
                );
              })}
              <MenuItem
                onClick={handleOpenAddressDialog}
                address-type="delivery_to"
              >
                {t("Add new")}
              </MenuItem>
            </Select>
          </FormControl>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth required>
          <FormLabel>{t("Invoice Addresses")}</FormLabel>
          <FormControl fullWidth sx={{ marginTop: 1 }}>
            <InputLabel id="invoice-address-label">{t("Address")}</InputLabel>
            <Select
              labelId="invoice-address-label"
              label={t("Select")}
              size="sm"
              fullWidth
              onChange={(e) => {
                handleChange(
                  {
                    target: {
                      id: "invoice_to_address",
                      value: e.target.value,
                    },
                  },
                  "main"
                );
              }}
              value={inputValue.main.invoice_to_address ?? ''}
            >
              {invoice.map((address) => {
                return (
                  <MenuItem value={address.ID} key={address.ID}>
                    {formatAddress(address, "invoice_to")}
                  </MenuItem>
                );
              })}
              <MenuItem
                onClick={handleOpenAddressDialog}
                address-type="invoice_to"
              >
                {t("Add new")}
              </MenuItem>
            </Select>
          </FormControl>
        </FormControl>
      </Grid>
    </Grid>
  );
}
