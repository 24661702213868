import React from "react";
import {
  FormControl,
  InputLabel,
  Input,
  DialogTitle,
  IconButton,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "../../axios";
import { Close as CloseIcon } from "@mui/icons-material";
import countries from "../../countries";
import { useState } from "react";
import { useContext } from "react";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";

export default function AddressDialog({
  open,
  setOpen,
  handleChange,
  inputValue,
  refresh,
  onSave,
}) {
  const [isInApi, setIsInApi] = useState(false);
  const { setSnackbar } = useContext(DataContext);
  const { t } = useTranslation();

  const saveAddress = () => {
    setIsInApi(true);

    let account = null;

    if (inputValue.address.address_type === "ordered_by") {
      account = inputValue.main.ordered_by_account;
    } else if (inputValue.address.address_type === "delivery_to") {
      account = inputValue.main.deliver_to_account;
    } else if (inputValue.address.address_type === "invoice_to") {
      account = inputValue.main.invoice_to_account;
    }

    axios
      .post("/my-account/addresses", {
        ...inputValue.address,
        account_id: account,
      })
      .then((response) => {
        refresh().then(() => {
          onSave(
            response.data.data.ID,
            "address",
            inputValue.address.address_type
          );
          close();
        });
      })
      .catch(() => {
        setSnackbar({
          message: t("An error occured while saving."),
          severity: "error",
          open: true,
        });

        setIsInApi(false);
      });
  };

  const close = () => {
    setOpen((prevState) => {
      return { ...prevState, address: false };
    });

    setIsInApi(false);
  };

  return (
    <Dialog sx={{ m: 0, p: 2 }} open={open} onClose={close}>
      <DialogTitle>
        {inputValue.address.address_id == null ? t("New") : t("Edit")}{" "}
        <Box sx={{ display: "none" }}>{inputValue.address.address_type}</Box>
        {inputValue.address.address_type === "ordered_by"
          ? t("Ordered By")
          : inputValue.address.address_type === "delivery_to"
            ? t("Delivery To")
            : t("Invoice")}{" "}
        Address
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ marginTop: 1 }}>
          <FormControl sx={{ width: "100%", marginBottom: 1 }}>
            <InputLabel htmlFor="address_line_1">{t("Address Line 1")}</InputLabel>
            <Input
              id="address_line_1"
              onChange={handleChange}
              value={inputValue.address.address_line_1}
            />
          </FormControl>
          <FormControl sx={{ width: "100%", marginBottom: 1 }}>
            <InputLabel htmlFor="address_line_2">{t("Address Line 2")}</InputLabel>
            <Input
              id="address_line_2"
              onChange={handleChange}
              value={inputValue.address.address_line_2}
            />
          </FormControl>
          <FormControl sx={{ width: "100%", marginBottom: 1 }}>
            <InputLabel htmlFor="address_line_3">{t("Address Line 3")}</InputLabel>
            <Input
              id="address_line_3"
              onChange={handleChange}
              value={inputValue.address.address_line_3}
            />
          </FormControl>
          <FormControl sx={{ width: "100%", marginBottom: 1 }}>
            <InputLabel htmlFor="address_phone">{t("Phone")}</InputLabel>
            <Input
              id="address_phone"
              onChange={handleChange}
              value={inputValue.address.address_phone}
            />
          </FormControl>
          <FormControl sx={{ width: "100%", marginBottom: 1 }}>
            <InputLabel htmlFor="address_postcode">{t("Postcode")}</InputLabel>
            <Input
              id="address_postcode"
              onChange={handleChange}
              value={inputValue.address.address_postcode}
            />
          </FormControl>
          <FormControl
            sx={{ width: "100%", marginBottom: 1 }}
            variant="standard"
          >
            <InputLabel id="country-label">{t("Country")}</InputLabel>
            <Select
              labelId="country-label"
              label={t("Country")}
              value={inputValue.address.address_country}
              onChange={handleChange}
            >
              {countries.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "100%", marginBottom: 1 }}>
            <InputLabel htmlFor="address_city">{t("City")}</InputLabel>
            <Input
              id="address_city"
              onChange={handleChange}
              value={inputValue.address.address_city}
            />
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
            <Button
              variant="contained"
              onClick={saveAddress}
              disabled={isInApi}
            >
              {t("Save")}
            </Button>
          </Box>
          <IconButton
            aria-label="close"
            onClick={close}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
