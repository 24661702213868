import React from "react";
import { Box, Container, Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { VscArrowSmallRight } from "react-icons/vsc";
import styles from "../../components/Navbar/Styles.module.scss";
import { Bars } from "react-loader-spinner";
import { DataContext } from "../../App";
import { validateResetPassword } from "../../components/Handle";
import axios from "../../axios";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ResetPassword() {
  const urlParams = useParams();
  const hash = urlParams.hash;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setSnackbar } = useContext(DataContext);
  const [inputValue, setInputValue] = useState({
    passwordAgain: "",
    password: "",
  });
  const [loginButton, setLoginButton] = useState({
    children: (
      <>
        {t("UPDATE PASSWORD")} <VscArrowSmallRight />
      </>
    ),
    status: false,
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const handleChange = (event) => {
    setInputValue((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value,
    }));
  };

  useEffect(() => {
    setErrors(validateResetPassword(inputValue, t));
  }, [inputValue, touched]);

  const focusHandler = (event) => {
    setTouched({ ...touched, [event.target.id]: true });
  };

  const updatePassword = async () => {
    if (!Object.keys(errors).length) {
      setLoginButton({
        children: <Bars height="20" width="80" color="var(--primary)" />,
        status: true,
      });

      axios
        .post("/update-password", { ...inputValue, hash: hash })
        .then((response) => {
          setSnackbar({
            message: t(response.data.message),
            severity: "success",
            open: true,
          });
          navigate("/");
        })
        .catch(({ response }) => {
          setSnackbar({
            message: t(response.data.message),
            severity: "error",
            open: true,
          });

          setLoginButton({
            children: (
              <>
                {t("UPDATE PASSWORD")} <VscArrowSmallRight />
              </>
            ),
            status: false,
          });
        });
    } else {
      setTouched({
        passwordAgain: true,
        password: true,
      });
    }
  };

  return (
    <Container sx={{ marginTop: 4, marginBottom: 2, width: "100%" }}>
      <Grid
        container
        sx={{ display: "flex", justifyContent: "center", width: "100%" }}
      >
        <Grid
          container
          item
          sm={8}
          sx={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Box>
            <div className={styles.inputs} style={{ marginBottom: "13px" }}>
              <div>
                <label htmlFor="password">{t("Password")} *</label>
              </div>
              <input
                defaultValue={inputValue.password}
                onChange={handleChange}
                onFocus={focusHandler}
                type="password"
                id="password"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    updatePassword();
                  }
                }}
              />
              <div>
                {errors.password && touched.password && (
                  <p>{errors.password}</p>
                )}
              </div>
            </div>
            <div className={styles.inputs} style={{ marginBottom: "13px" }}>
              <div>
                <label htmlFor="password">{t("Password Again")} *</label>
              </div>
              <input
                defaultValue={inputValue.passwordAgain}
                onChange={handleChange}
                onFocus={focusHandler}
                type="password"
                id="passwordAgain"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    updatePassword();
                  }
                }}
              />
              <div>
                {errors.passwordAgain && touched.passwordAgain && (
                  <p>{errors.passwordAgain}</p>
                )}
              </div>
            </div>
            <div className={styles.login}>
              <button
                disabled={loginButton.status}
                className={loginButton.status ? styles.none : ""}
                onClick={updatePassword}
              >
                {loginButton.children}
              </button>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
