/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./Styles.module.scss";
import { useParams } from "react-router-dom";
import { Breadcrumbs, Container, Grid, Link as BCLink } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import axios from "../../axios";
import { default as OrderDetailsContainer } from "../../components/MyAccount/OrderDetails";
import MiniLoading from "../../components/MiniLoading";
import { useTranslation } from "react-i18next";

export default function OrderDetails() {
  const urlParams = useParams();
  const { t } = useTranslation();
  const id = urlParams.id;

  const [order, setOrder] = useState(null);

  useEffect(() => {
    fetchOrder().then(function (res) {
      setOrder(res);
    });
  }, []);

  const fetchOrder = async () => {
    return await new Promise(function (resolve, reject) {
      axios
        .get("/my-account/orders/" + id)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch(reject);
    });
  };

  return (
    <div>
      <Container sx={{ pb: "1rem" }}>
        <div role="presentation">
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNext fontSize="small" />}
            sx={{ p: "14px 0" }}
          >
            <BCLink
              className={styles.brdHov}
              underline="none"
              color="inherit"
              href="/"
              fontSize={"14px"}
            >
              {t("Home")}
            </BCLink>
            <BCLink
              className={styles.brdHov}
              underline="none"
              color="inherit"
              href="/my-account"
              fontSize={"14px"}
            >
              {t("My Account")}
            </BCLink>
            <BCLink
              className={styles.brdHov}
              underline="none"
              color="inherit"
              href="/my-account/orders"
              fontSize={"14px"}
            >
              {t("Orders")}
            </BCLink>
            <BCLink
              underline="none"
              color="text.primary"
              href={"/my-account/orders/" + id}
              aria-current="page"
              fontSize={"14px"}
            >
              {t("Order")} #{order?.OrderNumber ?? "-"}
            </BCLink>
          </Breadcrumbs>
        </div>
        <Grid container item spacing={2} sx={{ justifyContent: "center" }}>
          <Grid container item>
            {order == null ? (
              <MiniLoading />
            ) : (
              <OrderDetailsContainer data={order} />
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
