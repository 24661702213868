import axios from "axios";
import Auth from "./Auth";

var instance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  headers: {
    Authorization: "Bearer " + Auth.getToken(),
  },
});

instance.interceptors.request.use(function (config) {
  config.headers["Authorization"] = "Bearer " + Auth.getToken();
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 && Auth.isAuthenticated()) {
      Auth.deleteToken();
      Auth.deleteUser();
      window.location = "/";
      return error;
    }

    throw error;
  }
);

export default instance;
