import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Box,
  Collapse,
  IconButton,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Quantity from "../Quantity";
import { numberFormatter } from "../../formatter";
import moment from "moment/min/moment-with-locales";
import Auth from "../../Auth";
import MiniLoading from "../MiniLoading";
import styles from "./Styles.module.scss";
import { useTranslation } from "react-i18next";
import 'moment/locale/nl';

moment().locale('nl');

const Line = ({ line }) => {
  return (
    <>
      <TableRow key={line.ID}>
        <TableCell>
          <Box
            component="div"
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Quantity id={line.Item} sku={line.ItemCode} isOrderPage={true} />
          </Box>
        </TableCell>
        <TableCell
          style={{
            width: "15%",
          }}
        >
          {line.ItemCode}
        </TableCell>
        <TableCell
          style={{
            width: "35%",
          }}
        >
          {line.Description}
        </TableCell>
        <TableCell
          style={{
            width: "5%",
          }}
        >
          {line.Quantity}
        </TableCell>
        <TableCell
          style={{
            width: "7%",
          }}
        >
          {numberFormatter.format(
            Math.abs(line.AmountDC) +
            Math.abs(
              typeof line.VATAmountDC == "undefined"
                ? line.VATAmount
                : line.VATAmountDC
            )
          )}
        </TableCell>
        <TableCell
          style={{
            width: "8%",
          }}
        >
          {line.VATPercentage ? parseFloat(line.VATPercentage) * 100 : "0"}%
        </TableCell>
        <TableCell
          style={{
            width: "7%",
          }}
        >
          {numberFormatter.format(Math.abs(line.AmountDC))}
        </TableCell>
      </TableRow>
    </>
  );
};

const Row = ({ row }) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  return (
    <>
      <TableRow
        key={row.Item}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        {Auth.getUser().CanOrder && (
          <TableCell scope="row">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        <TableCell component="th" scope="row">
          {row.OrderNumber}
        </TableCell>
        <TableCell>{moment(row.OrderDate).locale('nl').format('LL')}</TableCell>
        <TableCell>
          {row.invoices.length === 0 ? (
            <>{t("There are no invoices.")}</>
          ) : (
            row.invoices.map((invoice) => {
              return (
                <span key={invoice.InvoiceID}>
                  <Link href={"/my-account/invoices/" + invoice.InvoiceID}>
                    {t("Invoice")} #{invoice.InvoiceNumber}
                  </Link>
                  <br />
                </span>
              );
            })
          )}
        </TableCell>
        <TableCell>{row.YourRef}</TableCell>
        <TableCell>{numberFormatter.format(row.AmountDC)}</TableCell>
        <TableCell>
          <Link href={"/my-account/orders/" + row.OrderID}>{t("Details")}</Link>
        </TableCell>
      </TableRow>
      {Auth.getUser().CanOrder ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>
                        <span className={styles.tableThead}>{t("SKU")}</span>
                      </TableCell>
                      <TableCell>
                        <span className={styles.tableThead}>{t("Description")}</span>
                      </TableCell>
                      <TableCell>
                        <span className={styles.tableThead}>{t("Quantity")}</span>
                      </TableCell>
                      <TableCell>
                        <span className={styles.tableThead}>{t("Price")}</span>
                      </TableCell>
                      <TableCell>
                        <span className={styles.tableThead}>{t("VAT %")}</span>
                      </TableCell>
                      <TableCell>
                        <span className={styles.tableThead}>
                          {t("Amount excl. VAT")}
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.lines.map((line) => (
                      <Line key={line.ID} line={line} />
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : (
        <></>
      )}
    </>
  );
};

export default function OrdersTable(props) {
  const NoRecords = () => (
    <TableRow>
      <TableCell colSpan={7} sx={{ textAlign: "center" }}>
        {t("There are no records.")}
      </TableCell>
    </TableRow>
  );
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            {Auth.getUser().CanOrder && <TableCell></TableCell>}
            <TableCell>
              <span className={styles.tableThead}>{t("Order Number")}</span>
            </TableCell>
            <TableCell>
              <span className={styles.tableThead}>{t("Order Date")}</span>
            </TableCell>
            <TableCell>
              <span className={styles.tableThead}>{t("Invoices")}</span>
            </TableCell>
            <TableCell>
              <span className={styles.tableThead}>{t("Reference")}</span>
            </TableCell>
            <TableCell>
              <span className={styles.tableThead}>{t("Total")}</span>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data == null ? (
            <TableRow>
              <TableCell colSpan={7}>
                <MiniLoading />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {props.data.data.length === 0 && <NoRecords />}
              {props.data.data?.map((row) => (
                <Row row={row} key={row.ID} />
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
