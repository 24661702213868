import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MiniLoading from "./MiniLoading";
import moment from "moment/min/moment-with-locales";
import { useTranslation } from "react-i18next";


const Row = ({ data }) => {
  return (
    <TableRow
      key={data.ID}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        {data.id}
      </TableCell>
      <TableCell>{data.type}</TableCell>
      <TableCell>{data.message}</TableCell>
      <TableCell>{data.extras ?? "-"}</TableCell>
      <TableCell>{moment(data.created_at).locale('nl').format("LLL")}</TableCell>
    </TableRow>
  );
};

export default function LogsTable(props) {
  const Alert = (props) => (
    <TableRow>
      <TableCell colSpan={5} sx={{ textAlign: "center" }}>
        {props.message}
      </TableCell>
    </TableRow>
  );
  const { t } = useTranslation();

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>{t("Id")}</TableCell>
              <TableCell>{t("Type")}</TableCell>
              <TableCell>{t("Message")}</TableCell>
              <TableCell>{t("Extras")}</TableCell>
              <TableCell>{t("Created At")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data == null ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <MiniLoading />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {
                  props.data.data.length === 0 && <Alert message={t("There are no logs.")} />}
                {props.data.data?.map((row) => (
                  <Row
                    key={row.ID}
                    data={row}
                  />
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
