/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import styles from "./Styles.module.scss";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link as BCLink,
  Pagination,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import axios from "../../axios";
import ProductCard from "../../components/ProductCard/ProductCard";
import { numberFormatter } from "../../formatter";
import MiniLoading from "../../components/MiniLoading";
import Auth from "../../Auth";
import { calculateSpecialPrice } from "../../components/Cart";
import { useTranslation } from "react-i18next";

const MostPurchasedProducts = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    refresh(null);
  }, []);

  const refresh = (event, page = null) => {
    setProducts(null);
    fetchProducts(page).then(function (res) {
      setProducts(res);
    });
  };

  const fetchProducts = async (page = null) => {
    return await new Promise(function (resolve, reject) {
      if (
        !Auth.isAuthenticated() ||
        (Auth.getUser()?.CanOrder !== true && !Auth.getUser().IsAdmin)
      ) {
        resolve({ data: [] });
      }
      axios
        .get("/my-account/most-purchased-products?page=" + (page ?? 0))
        .then((response) => {
          resolve(response.data.data);
        })
        .catch(reject);
    });
  };

  const handleClick = (event) => {
    event.preventDefault();
    navigate(event.target.pathname);
  };

  const NoRecords = () => (
    <Box display={"flex"} justifyContent={"center"} width={"100%"}>
      {t("There are no records.")}
    </Box>
  );

  return (
    <div>
      <Container sx={{ pb: "1rem" }}>
        <div role="presentation" onClick={handleClick}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNext fontSize="small" />}
            sx={{ p: "14px 0" }}
          >
            <BCLink
              className={styles.brdHov}
              underline="none"
              color="inherit"
              href="/"
              fontSize={"14px"}
            >
              {t("Home")}
            </BCLink>
            <BCLink
              className={styles.brdHov}
              underline="none"
              color="inherit"
              href="/my-account"
              fontSize={"14px"}
            >
              {t("My Account")}
            </BCLink>
            <BCLink
              underline="none"
              color="text.primary"
              href="/my-account/most-purchased-products"
              aria-current="page"
              fontSize={"14px"}
            >
              {t("Frequently Bought")}
            </BCLink>
          </Breadcrumbs>
        </div>
        <Grid
          container
          item
          xs={12}
          spacing={1}
          alignItems="center"
          justify="center"
        >
          {products == null ? (
            <MiniLoading />
          ) : (
            <>
              {products.data?.length === 0 && <NoRecords />}
              {products.data?.length > 0 &&
                products.data.map((product, idx) => {
                  return (
                    <Grid container item sm={3} key={product.ID}>
                      <ProductCard
                        data={product}
                        sty={{ height: "100%" }}
                        spacing={5}
                      >
                        <span>
                          {calculateSpecialPrice(product) != null ? (
                            <>
                              <strike>
                                {numberFormatter.format(
                                  product.sales_item_price.Price
                                )}
                              </strike>{" "}
                              {numberFormatter.format(
                                calculateSpecialPrice(product)
                              )}
                            </>
                          ) : (
                            numberFormatter.format(
                              product.sales_item_price.Price
                            )
                          )}
                        </span>
                      </ProductCard>
                    </Grid>
                  );
                })}
              <Container
                sx={{ marginTop: 2, display: "flex", justifyContent: "center" }}
              >
                <Pagination
                  count={products.last_page}
                  page={products.current_page}
                  onChange={refresh}
                />
              </Container>
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default React.memo(MostPurchasedProducts);
