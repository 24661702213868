import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function SearchBox({
  setSearchValue,
  searchValue,
  navigateOnEnter = null,
  placeholder = "Search",
  extraStyle = {},
  autoComplete = false,
}) {
  const navigate = useNavigate();

  return (
    <OutlinedInput
      id="search"
      style={extraStyle}
      type={"text"}
      onChange={(event) => {
        setSearchValue(event.target.value);
      }}
      autoComplete={autoComplete ? "on" : "off"}
      value={searchValue}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          if (navigateOnEnter != null) {
            navigate(navigateOnEnter);
          }
        }
      }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            edge="end"
            size="small"
            onClick={() => {
              if (navigateOnEnter != null) {
                navigate(navigateOnEnter);
              }
            }}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      }
      placeholder={placeholder}
    />
  );
}
