/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./Styles.module.scss";
import {
  Breadcrumbs,
  Container,
  Grid,
  Link as BCLink,
  Pagination,
  Box,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import axios from "../../axios";
import InvoicesTable from "../../components/MyAccount/InvoicesTable";
import SearchBox from "../../components/Navbar/SearchBox";
import { useTranslation } from "react-i18next";

const Orders = () => {
  const [invoices, setInvoices] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setInvoices(null);
      fetch();
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchValue]);

  const fetch = () => {
    fetchInvoices().then(function (res) {
      setInvoices(res);
    });
  };

  const fetchInvoices = async (page) => {
    return await new Promise(function (resolve, reject) {
      axios
        .get(
          "/my-account/invoices?page=" + (page ?? 0) + "&search=" + searchValue
        )
        .then((response) => {
          resolve(response.data.data);
        })
        .catch(reject);
    });
  };

  const refresh = (event, page = null) => {
    setInvoices(null);
    fetchInvoices(page).then(function (res) {
      setInvoices(res);
    });
  };

  return (
    <div>
      <Container sx={{ pb: "1rem" }}>
        <div role="presentation">
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNext fontSize="small" />}
            sx={{ p: "14px 0" }}
          >
            <BCLink
              className={styles.brdHov}
              underline="none"
              color="inherit"
              href="/"
              fontSize={"14px"}
            >
              {t("Home")}
            </BCLink>
            <BCLink
              className={styles.brdHov}
              underline="none"
              color="inherit"
              href="/my-account"
              fontSize={"14px"}
            >
              {t("My Account")}
            </BCLink>
            <BCLink
              underline="none"
              color="text.primary"
              href="/my-account/invoices"
              aria-current="page"
              fontSize={"14px"}
            >
              {t("Invoices")}
            </BCLink>
          </Breadcrumbs>
        </div>
        <Grid container item spacing={2} sx={{ justifyContent: "center" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <SearchBox
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              placeholder={t("Search invoices")}
              extraStyle={{ margin: "0px 0px 20px 0px" }}
              navigateOnEnter={null}
            />
          </Box>
          <Grid container item>
            <InvoicesTable data={invoices} />
            <Container
              sx={{
                marginTop: 2,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                count={invoices?.last_page || 0}
                page={invoices?.current_page || 0}
                onChange={refresh}
              />
            </Container>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default React.memo(Orders);
