import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Checkbox } from "@mui/material";
import axios from "../../axios";
import { DataContext } from "../../App";
import MiniLoading from "../MiniLoading";
import SetPasswordDialog from "./SetPasswordDialog";
import Auth from "../../Auth";
import { useTranslation } from "react-i18next";

const Row = ({ data, handleOpenPasswordDialog }) => {
  const [canOrder, setCanOrder] = React.useState(data.CanOrder);
  const [isAdmin, setIsAdmin] = React.useState(data.IsAdmin);
  const [isInApi, setIsInApi] = React.useState(false);
  const { setSnackbar } = useContext(DataContext);
  const { t } = useTranslation();

  const changeCanOrder = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setCanOrder((prevState) => {
      setIsInApi(true);

      axios
        .put(
          "/admin/accounts/" + e.target.getAttribute("data-id") + "/can-order",
          { can_order: !prevState }
        )
        .catch(() => {
          setCanOrder(data.CanOrder);
        })
        .finally(() => {
          setIsInApi(false);
        });

      return !prevState;
    });
  };

  const changeIsAdmin = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setIsAdmin((prevState) => {
      setIsInApi(true);

      axios
        .put(
          "/admin/accounts/" + e.target.getAttribute("data-id") + "/is-admin",
          { is_admin: !prevState }
        )
        .catch(() => {
          setIsAdmin(data.IsAdmin);
        })
        .finally(() => {
          setIsInApi(false);
        });

      return !prevState;
    });
  };

  const sendPasswordMail = (e) => {
    let sure = window.confirm(
      "Password mail will be send for " +
      e.target.getAttribute("data-email") +
      ", are you sure?"
    );

    if (sure) {
      setIsInApi(true);

      axios
        .post(
          "/admin/accounts/" +
          e.target.getAttribute("data-id") +
          "/send-password-link"
        )
        .then((response) => {
          setIsInApi(false);

          setSnackbar({
            message: t("Successfully sent password mail."),
            severity: "success",
            open: true,
          });
        })
        .catch((err) => {
          setSnackbar({
            message: t("Error while sending password mail."),
            severity: "error",
            open: true,
          });
        })
        .finally(() => {
          setIsInApi(false);
        });
    }
  };

  return (
    <TableRow
      key={data.ID}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        {data.Code}
      </TableCell>
      <TableCell>{data.Name}</TableCell>
      <TableCell>{data.Email}</TableCell>
      <TableCell>
        <Checkbox

          checked={canOrder}
          inputProps={{
            "data-id": data.ID,
          }}
          onChange={changeCanOrder}
          disabled={isInApi}
        />
      </TableCell>
      {/* <TableCell>
        <Checkbox
          color="apimasters"
          checked={isAdmin}
          inputProps={{
            "data-id": data.ID,
          }}
          onChange={changeIsAdmin}
          disabled={isInApi}
        />
      </TableCell> */}
      <TableCell style={{ fontSize: '0.85rem' }}>
        <Button
          color="apimasters"
          variant="contained"
          data-id={data.ID}
          data-email={data.Email}
          onClick={sendPasswordMail}
          size="small"
          sx={{
            minWidth: '180px',
            padding: '5px 5px'
          }}
          disabled={
            isInApi ||
            (data.Email != null && data.Email.trim() === "") ||
            data.Email == null
          }
        >
          {t("SendPasswordMail")}
        </Button>
      </TableCell>

      <TableCell style={{ fontSize: '0.85rem' }}>
        <Button
          color="apimasters"
          variant="contained"
          data-id={data.ID}
          data-name={data.Name + " (" + data.Code.trim() + ")"}
          onClick={handleOpenPasswordDialog}
          disabled={isInApi}
          size="small"
          sx={{
            minWidth: '180px',
            padding: '5px 5px'
          }}
        >
          {t("SetPassword")}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default function AccountsTable(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedAccount, setSelectedAccount] = React.useState(null);
  const { t } = useTranslation();

  const handleOpenPasswordDialog = (e) => {
    setSelectedAccount({
      id: e.target.getAttribute("data-id"),
      name: e.target.getAttribute("data-name"),
    });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const Alert = (props) => (
    <TableRow>
      <TableCell colSpan={4} sx={{ textAlign: "center" }}>
        {props.message}
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {open && (
        <SetPasswordDialog
          handleClose={handleClose}
          selectedAccount={selectedAccount}
        />
      )}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>{t("Code")}</TableCell>
              <TableCell>{t("Name")}</TableCell>
              <TableCell>{t("Email")}</TableCell>
              {/* <TableCell>Pay By Invoice</TableCell> */}
              <TableCell>{t("CanOrder")}</TableCell>
              {/* <TableCell>IsAdmin</TableCell> */}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data == null ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <MiniLoading />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {Auth.getUser().store_division == null ? <Alert message={t("First please do auth at Authentication page.")} /> :
                  props.data.data.length === 0 && <Alert message={t("There are no accounts.")} />}
                {Auth.getUser().store_division != null
                  && props.data.data?.map((row) => (
                    <Row
                      key={row.ID}
                      data={row}
                      handleOpenPasswordDialog={handleOpenPasswordDialog}
                    />
                  ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
