/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./Styles.module.scss";
import {
  Breadcrumbs,
  Container,
  Grid,
  Link as BCLink,
  Box,
  Pagination,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import axios from "../../axios";
import AccountsTable from "../../components/Accounts/AccountsTable";
import SearchBox from "../../components/Navbar/SearchBox";
import { Trans, useTranslation } from "react-i18next";

const Accounts = () => {
  const [accounts, setAccounts] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setAccounts(null);
      fetch();
    }, 500);

    return () => clearTimeout(timer);
  }, [searchValue]);

  const fetch = () => {
    fetchAccounts().then(function (res) {
      setAccounts(res);
    });
  };

  const fetchAccounts = async (page) => {
    return await new Promise(function (resolve, reject) {
      axios
        .get("/admin/accounts?page=" + (page ?? 0) + "&search=" + searchValue)
        .then((response) => {
          console.log(response.data);
          resolve(response.data);
        })
        .catch(reject);
    });
  };

  const refresh = (event, page = null) => {
    setAccounts(null);
    fetchAccounts(page).then(function (res) {
      setAccounts(res);
    });
  };

  const { t } = useTranslation();
  const nextSync = accounts?.next_sync ?? '-';

  return (
    <div>
      <Container sx={{ pb: "1rem" }}>
        <div role="presentation">
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNext fontSize="small" />}
            sx={{ p: "14px 0" }}
          >
            <BCLink
              className={styles.brdHov}
              underline="none"
              color="inherit"
              href="/"
              fontSize={"14px"}
            >
              {t("Home")}
            </BCLink>
            {/* <BCLink
              className={styles.brdHov}
              underline="none"
              color="inherit"
              href="/admin"
              fontSize={"14px"}
            >
              Admin
            </BCLink> */}
            <BCLink
              underline="none"
              color="text.primary"
              href="/store/accounts"
              aria-current="page"
              fontSize={"14px"}
            >
              {t("Accounts")}
            </BCLink>
          </Breadcrumbs>
        </div>
        <Grid container item spacing={2} sx={{ justifyContent: "center" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <SearchBox
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              placeholder={t("Search accounts")}
              extraStyle={{ margin: "0px 0px 20px 0px" }}
              navigateOnEnter={null}
            />
          </Box>
          <Grid container item>
            {/* {accounts == null ? (
              <Loading />
            ) : (
              <> */}
            <Box sx={{ paddingLeft: 3, paddingBottom: 3, fontSize: "13px" }}>
              <ol>
                <li><Trans i18nKey="account_sync_information"
                  nextSync={nextSync}>If you cannot find the Account you are looking for, the system will sync after {{ nextSync }}, please check again after {{ nextSync }}.</Trans></li>
                <li>{t("This page is where you will let your customers to be able to login (up on Can Order mark) also you can set password for them or send them link to set one.")}</li>
                <li>{t("Users are not marked as Can Order also can login system if you set them a password, difference is they can not order but can see order and invoice history.")}</li>
              </ol>
            </Box>
            <AccountsTable data={accounts?.data} searchValue={searchValue} />
            <Container
              sx={{
                marginTop: 2,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                count={accounts?.data?.last_page || 0}
                page={accounts?.data?.current_page || 0}
                onChange={refresh}
              />
            </Container>
            {/* </> */}
            {/* )} */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default React.memo(Accounts);
