/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useContext, useState } from "react";
import { DataContext } from "../../App";
import styles from "./Styles.module.scss";
import {
    Breadcrumbs,
    Container,
    Grid,
    Link as BCLink,
    Box,
    Pagination,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import axios from "../../axios";
import ProductCard from "../../components/ProductCard/ProductCard";
import { numberFormatter } from "../../formatter";
import MiniLoading from "../../components/MiniLoading";
import { calculateSpecialPrice } from "../../components/Cart";
import { useTranslation } from "react-i18next";

const Products = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState(null);
    const { searchValue } = useContext(DataContext);
    const urlParams = useParams();
    const { t } = useTranslation();
    const categoryID = urlParams?.categoryID;

    useEffect(() => {
        const timer = setTimeout(() => {
            refresh();
        }, 1000);

        return () => clearTimeout(timer);
    }, [searchValue]);

    const refresh = (event, page = null) => {
        setProducts(null);
        fetchProducts(page).then(function (res) {
            setProducts(res);
        });
    };

    const fetchProducts = async (page = null) => {
        return await new Promise(function (resolve, reject) {
            if (typeof categoryID == "undefined" || categoryID == null) {
                // if (searchValue.replace(" ", "").length === 0) {
                //   resolve({
                //     data: [],
                //   });

                //   return;
                // }

                axios
                    .get(
                        "/products?page=" +
                            (page ?? 0) +
                            "&search=" +
                            searchValue
                    )
                    .then((response) => {
                        resolve(response.data.data);
                    })
                    .catch(reject);
            } else {
                axios
                    .get(
                        "/products/category/" +
                            categoryID +
                            "?page=" +
                            (page ?? 0) +
                            "&search=" +
                            searchValue
                    )
                    .then((response) => {
                        resolve(response.data.data);
                    })
                    .catch(reject);
            }
        });
    };

    const handleClick = (event) => {
        event.preventDefault();
        navigate(event.target.pathname);
    };

    const NoRecords = () => (
        <Box display={"flex"} justifyContent={"center"} width={"100%"}>
            {t("There are no records.")}
        </Box>
    );

    return (
        <div>
            <Container sx={{ pb: "1rem" }}>
                <div role="presentation" onClick={handleClick}>
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        separator={<NavigateNext fontSize="small" />}
                        sx={{ p: "14px 0" }}
                    >
                        <BCLink
                            className={styles.brdHov}
                            underline="none"
                            color="inherit"
                            href="/"
                            fontSize={"14px"}
                        >
                            {t("Home")}
                        </BCLink>
                        <BCLink
                            underline="none"
                            color="text.primary"
                            href="/products"
                            aria-current="page"
                            fontSize={"14px"}
                        >
                            {t("Products")}
                        </BCLink>
                    </Breadcrumbs>
                </div>
                <Grid
                    container
                    item
                    xs={12}
                    spacing={1}
                    alignItems="center"
                    justify="center"
                >
                    {products == null ? (
                        <MiniLoading />
                    ) : (
                        <>
                            {products.data?.length === 0 && <NoRecords />}
                            {products.data?.length > 0 &&
                                products.data.map((product, idx) => {
                                    if (product.sales_item_price == null) {
                                        return <></>;
                                    }

                                    return (
                                        <Grid
                                            container
                                            item
                                            sm={3}
                                            key={product.ID}
                                        >
                                            <ProductCard
                                                data={product}
                                                sty={{ height: "100%" }}
                                                spacing={5}
                                            >
                                                <span>
                                                    {calculateSpecialPrice(
                                                        product
                                                    ) != null ? (
                                                        <>
                                                            <strike>
                                                                {numberFormatter.format(
                                                                    product
                                                                        .sales_item_price
                                                                        .Price
                                                                )}
                                                            </strike>{" "}
                                                            {numberFormatter.format(
                                                                calculateSpecialPrice(
                                                                    product
                                                                )
                                                            )}
                                                        </>
                                                    ) : (
                                                        numberFormatter.format(
                                                            product
                                                                .sales_item_price
                                                                .Price
                                                        )
                                                    )}
                                                </span>
                                            </ProductCard>
                                        </Grid>
                                    );
                                })}
                            <Container
                                sx={{
                                    marginTop: 2,
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Pagination
                                    count={products.last_page}
                                    page={products.current_page}
                                    onChange={refresh}
                                />
                            </Container>
                        </>
                    )}
                </Grid>
            </Container>
        </div>
    );
};

export default React.memo(Products);
