import { Container, Grid } from "@mui/material";
import React from "react";
import logo from "../../images/apimasters-logo.svg";
import styles from "./Styles.module.scss";
import Auth from "../../Auth";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../../components/Navbar/TopNavBar";

const Footer = () => {
    const { t } = useTranslation();
    var logoUrl = logo;

    if (
        window.storeSettings != null &&
        window.storeSettings?.footer_logo !== null
    ) {
        logoUrl = window.storeSettings?.footer_logo;
        //  import.meta.env.VITE_BASE_URL +
        //   "/exact/store/logo/footer_" +
        //   window.storeSettings.division;
    }

    return (
        <Container>
            <div className={styles.main}>
                <Grid
                    container
                    spacing={2}
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    <Grid
                        item
                        sm={6}
                        lg={3}
                        className={styles.grid1}
                        paddingX={"16px"}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        <img
                            src={logoUrl}
                            alt="logo"
                            style={{ width: "100%" }}
                        />
                        <p
                            style={{
                                textAlign: "center",
                            }}
                        >
                            {window.storeSettings.site_name}
                            <br />
                            {window.storeSettings.address_section}
                        </p>
                    </Grid>
                    {/* <Grid item sm={6} lg={3} className={styles.grids} paddingX={"16px"}>
            <List
              title="Klantenservice"
              content={[
                <Link href={"https://webshop.ecotone.nl/service/about/"}>
                  Garantie
                </Link>,
                <Link
                  href={"https://webshop.ecotone.nl/service/payment-methods/"}
                >
                  Betaalmethodes
                </Link>,
                <Link
                  href={"https://webshop.ecotone.nl/service/shipping-returns/"}
                >
                  Verzenden en Retourneren
                </Link>,
                <Link href={"https://webshop.ecotone.nl/blogs/artikelen/"}>
                  Blogs
                </Link>,
                <Link href={"https://webshop.ecotone.nl/service/"}>
                  Contact
                </Link>,
              ]}
            />
          </Grid>
          <Grid item sm={6} lg={3} className={styles.grids} paddingX={"16px"}>
            <List
              title="apimasters"
              content={[
                <Link href={"https://webshop.ecotone.nl/service/about/"}>
                  Over ons
                </Link>,
                <Link
                  href={"https://webshop.ecotone.nl/service/over-verpakkingen/"}
                >
                  Onze verpakkingen
                </Link>,
                <Link href={"https://webshop.ecotone.nl/service/disclaimer/"}>
                  Disclaimer
                </Link>,
                <Link
                  href={"https://webshop.ecotone.nl/service/privacy-policy/"}
                >
                  Privacy Statement
                </Link>,
                <Link
                  href={
                    "https://webshop.ecotone.nl/service/general-terms-conditions/"
                  }
                >
                  Alg. leveringsvoorwaarden
                </Link>,
              ]}
            />
          </Grid> */}
                    {/* <Grid item sm={6} lg={3} className={styles.grids} paddingX={"16px"}> */}
                    {/* <List
              title="My Account"
              content={[
                "Sign In",
                "View Cart",
                "My Wishlist",
                "Track My Order",
                "Help",
              ]}
            /> */}
                    {/* </Grid> */}
                </Grid>
                <hr />
            </div>
            <hr />
            <div className={styles.bottom}>
                <p>
                    {t("Copyright")} © {new Date().getFullYear()}{" "}
                    {window.storeSettings.site_name}.{" "}
                    {t("All Rights Reserved.")}
                </p>
                <p style={{ display: "flex", alignItems: "center" }}>
                    {!window.storeSettings?.is_domain_approved && (
                        <>
                            <a
                                href="https://apimasters.nl"
                                style={{ color: "#0a5983" }}
                            >
                                ApiMasters
                            </a>
                            ® |{" "}
                            {t(
                                "Prepared with Exact Online B2B Order Application."
                            )}
                        </>
                    )}{" "}
                    <LanguageSelector />
                </p>
            </div>
        </Container>
    );
};

export default Footer;
