import SignIn from "../../components/Navbar/SignIn";
import { Container, Grid } from "@mui/material";

function Login() {
  return (
    <>
      <Container sx={{ marginTop: 4, marginBottom: 2, width: "100%" }}>
        <Grid
          container
          sx={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Grid
            container
            item
            sm={8}
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <SignIn></SignIn>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Login;
