/* eslint-disable no-unused-vars */
import {
    Box,
    Container,
    Grid,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    TextField,
    Card,
    CardContent,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { VscArrowSmallRight } from "react-icons/vsc";
import styles from "./Styles.module.scss";
import { Bars } from "react-loader-spinner";
import { DataContext } from "../../App";
import logo from "../../images/apimasters-logo.svg";
// import { validateForgotPassword, validateSignIn } from "../Handle";
import axios from "../../axios";
import Auth from "../../Auth";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import countries from "../../countries";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { useNavigate } from "react-router-dom";
import MiniLoading from "../../components/MiniLoading";
import * as helper from "../../helper";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useTranslation } from "react-i18next";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

export default function Settings() {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(1);
    const { t } = useTranslation();
    // const [color, setColor] = useColor("hex", "#121212");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container sx={{ marginTop: 4, marginBottom: 2, width: "100%" }}>

            <Box
                sx={{
                    width: "100%",
                    flexGrow: 1,
                    bgcolor: "background.paper",
                    display: "flex",
                    // height: 300,
                }}
            >
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    color="apimasters"
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{
                        borderRight: 1, borderColor: "divider",
                        ".Mui-selected": {
                            color: "var(--primary)"
                        },
                    }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "var(--primary)",
                        }
                    }}
                >
                    <Tab
                        label={t("Exact Online")}
                        {...a11yProps(0)}
                        onClick={() => {
                            navigate("/try-now");
                        }}

                    />
                    <Tab label={t("Shop Settings")} {...a11yProps(1)} />
                    {/* <Tab label="Imports" {...a11yProps(2)} /> */}
                </Tabs>
                <TabPanel value={value} index={0} style={{ width: "100%" }}>
                    <h2>{t("Exact Settings")}</h2>

                    <Box
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                        }}
                    >
                        <FormControl
                            sx={{ width: "30%", marginBottom: 1 }}
                            variant="standard"
                        >
                            <InputLabel id="country-label">{t("Country")}</InputLabel>
                            <Select
                                labelId="country-label"
                                label={t("Country")}
                                // value={inputValue.address.address_country}
                                onChange={handleChange}
                            >
                                {countries.map((item) => {
                                    return (
                                        <MenuItem
                                            key={item.value}
                                            value={item.value}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <FormControl
                            sx={{ width: "30%", marginBottom: 1 }}
                            variant="standard"
                        >
                            <InputLabel id="division-label">{t("Division")}</InputLabel>
                            <Select
                                labelId="division-label"
                                label="division"
                                onChange={handleChange}
                            >
                                {[123, 34234, 34].map((item) => {
                                    return (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <Box>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    // addToBasket(!isCartPage);
                                }}
                                color="apimasters"
                                sx={{ color: "white", width: "30%", marginTop: 2 }}
                            >
                                {t("Re-authorize")}
                            </Button>
                        </Box>
                    </Box>
                </TabPanel>
                <TabPanel
                    value={value}
                    index={1}
                    style={{ width: "100%", height: "100%" }}
                >
                    <WebsiteSettings />
                </TabPanel>
                <TabPanel value={value} index={2} style={{ width: "100%" }}>
                    <h2>{t("Settings")}</h2>

                    <Box
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            marginTop: 1,
                        }}
                    >
                        {[{ name: "Account" }, { name: "Contact" }].map(
                            (endpoint) => {
                                return (
                                    <FormControlLabel
                                        control={<Switch onChange={handleChange} />}
                                        label={
                                            <>
                                                {endpoint.name}{" "}
                                                <Button
                                                    variant="contained"
                                                    sx={{ marginLeft: 2 }}
                                                    size="small"
                                                >
                                                    {t("Force refresh")}
                                                </Button>
                                            </>
                                        }
                                    />
                                );
                            }
                        )}
                    </Box>
                </TabPanel>
            </Box>
        </Container>
    );
}

export function WebsiteSettings() {
    const [loading, setLoading] = React.useState(true);
    const [value, setValue] = React.useState(0);
    const [primaryColor, setPrimaryColor] = useColor("hex", (window.storeSettings.primary_color == null ||
        window.storeSettings.primary_color ===
        "default") ? "#121212" : window.storeSettings.primary_color);
    const [secondaryColor, setSecondaryColor] = useColor("hex", (window.storeSettings.secondary_color == null ||
        window.storeSettings.secondary_color ===
        "default") ? "#121212" : window.storeSettings.secondary_color);
    const { setSnackbar } = useContext(DataContext);
    const [saveButtonDisabled, setSaveButtonDisabled] = React.useState(false);
    const { t } = useTranslation();

    useEffect
        (() => {
            if (Auth.getUser().store_division == null) {
                setLoading(false);
            } else {
                axios
                    .get("/exact/store/details/" + Auth.getUser().store_division)
                    .then((response) => {
                        setInputValue(response.data.data);
                        setPrimaryColor({
                            "hex": response.data.data.primary_color,
                            rgb: helper.hexToRGB(response.data.data.primary_color),
                            hsv: helper.hexToHSL(response.data.data.primary_color)
                        });

                        setSecondaryColor({
                            "hex": response.data.data.secondary_color,
                            rgb: helper.hexToRGB(response.data.data.secondary_color),
                            hsv: helper.hexToHSL(response.data.data.secondary_color)
                        });

                        setLoading(false);
                    }).catch(() => {
                        setLoading(false);
                    });
            }
        }, []);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const [inputValue, setInputValue] = useState(window.storeSettings || {
        site_name: "",
        site_description: "",
        address_section: "",
        top_logo: null,
        language: "en",
        store_support_email: window.user.email,
    });

    const handleChange = (event) => {
        setInputValue((prevState) => ({
            ...prevState,
            [event.target.id ?? event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value,
        }));
    };

    const saveThemeSettings = () => {
        setSaveButtonDisabled(true);
        axios
            .post("/exact/store/settings", {
                primary_color: primaryColor.hex,
                secondary_color: secondaryColor.hex,
            })
            .then((response) => {
                setSnackbar({
                    message:
                        t("Theme settings saved successfully."),
                    severity: "success",
                    open: true,
                });

                localStorage.removeItem('currentStoreTheme');
                window.location.reload();
            })
            .catch(({ response }) => {
                console.log(response);
                setSnackbar({
                    message:
                        t(response.data?.message ||
                            "An error occured while saving store settings."),
                    severity: "error",
                    open: true,
                });
            }).finally(() => {
                setSaveButtonDisabled(false);
            });
    };

    const saveSettings = () => {
        setSaveButtonDisabled(true);
        axios
            .post("/exact/store/settings", inputValue)
            .then((response) => {
                setSnackbar({
                    message:
                        t("Site settings saved successfully."),
                    severity: "success",
                    open: true,
                });

                localStorage.removeItem('currentStoreTheme');
                window.location.reload();
            })
            .catch(({ response }) => {
                console.log(response);

                setSnackbar({
                    message:
                        t(response.data?.message ||
                            "An error occured while saving store settings."),
                    severity: "error",
                    open: true,
                });
            }).finally(() => {
                setSaveButtonDisabled(false);
            });
    };

    const handleCapture = ({ target }) => {
        const fileReader = new FileReader();
        const name = target.id;

        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
            setInputValue((prevState) => ({
                ...prevState,
                [name]: e.target.result,
            }));
        };
    };

    return (
        <>
            <h2>{t("Settings")}</h2>

            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChangeTab}
                        sx={{
                            borderRight: 1, borderColor: "divider",
                            ".Mui-selected": {
                                color: "var(--primary)"
                            },
                        }}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "var(--primary)",
                            }
                        }}
                    >
                        <Tab label={t("Theme settings")} {...a11yProps(0)} />
                        <Tab label={t("Shop settings")} {...a11yProps(1)} />
                        {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                    </Tabs>
                </Box>
                {loading ? <Box sx={{ marginTop: 4 }}>
                    <MiniLoading />
                </Box> :
                    Auth.getUser().store_division == null ?
                        <Box sx={{ marginTop: 4 }}>
                            <span >{t("Please do Exact authentication first")}</span>
                        </Box>
                        :
                        <>
                            <CustomTabPanel value={value} index={0}>
                                <Box>
                                    <h3>{t("Background main color")}</h3>
                                    <ColorPicker
                                        width={456}
                                        height={228}
                                        color={primaryColor}
                                        onChange={setPrimaryColor}
                                        hideHSV
                                    />
                                </Box>
                                {/* <Box>
                                    <h3>Foreground color</h3>
                                    <ColorPicker
                                        width={456}
                                        height={228}
                                        color={secondaryColor}
                                        onChange={setSecondaryColor}
                                        hideHSV
                                    />
                                </Box> */}
                                <Button
                                    variant="contained"
                                    sx={{ marginTop: 3 }}
                                    size="small"
                                    color="apimasters"
                                    onClick={saveThemeSettings}
                                    disabled={saveButtonDisabled}
                                >
                                    {t("Save")}
                                </Button>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <Container>
                                    <Grid
                                        container
                                        spacing={3}
                                        justifyContent="center"
                                        alignItems="stretch"
                                    >
                                        <Grid item xs={6}>
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Typography level="title-md">
                                                        {t("Shop Name")} *
                                                    </Typography>
                                                    <FormControl
                                                        sx={{ width: "100%", marginTop: 1 }}
                                                    >
                                                        <TextField
                                                            id="site_name"
                                                            // label="Title"
                                                            name="site_name"
                                                            onChange={handleChange}
                                                            value={inputValue.site_name}
                                                        />
                                                    </FormControl>
                                                </CardContent>
                                            </Card>
                                        </Grid><Grid item xs={6}>
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Typography level="title-md">
                                                        {t("Shop Domain (format: example.com)")}
                                                    </Typography>
                                                    <FormControl
                                                        sx={{ width: "100%", marginTop: 1 }}
                                                    >
                                                        <TextField
                                                            id="subdomain"
                                                            // label="Title"
                                                            name="subdomain"
                                                            onChange={handleChange}
                                                            value={inputValue.subdomain}
                                                        />
                                                    </FormControl>
                                                    <Typography level="title-sm" sx={{ marginTop: 2, marginBottom: 0 }}>
                                                        <b>{t("Status")}:</b> {inputValue.domain_status === "not sent for approval"
                                                            ? <span >{t("Please fill shop domain and save")}</span> :
                                                            inputValue.domain_status === "approved"
                                                                ?
                                                                <span style={{ color: "green" }}>{t("Approved")} (<a target="_blank" rel="noreferrer"
                                                                    href={"http://" + inputValue.subdomain}>{inputValue.subdomain} <OpenInNewIcon style={{ verticalAlign: "middle" }} fontSize="1rem" /></a>)</span> :
                                                                <span style={{ color: "orange" }}>{t("Pending")}</span>

                                                        }
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Typography level="title-md">
                                                        {t("Shop Description")}
                                                    </Typography>
                                                    <FormControl
                                                        sx={{ width: "100%", marginTop: 1 }}
                                                    >
                                                        <TextField
                                                            id="site_description"
                                                            // label="Site Description"
                                                            name="site_description"
                                                            multiline
                                                            rows={4}
                                                            onChange={handleChange}
                                                            value={inputValue.site_description}
                                                        />
                                                    </FormControl>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Typography level="title-md">
                                                        {t("Address Section")}
                                                    </Typography>
                                                    <FormControl
                                                        sx={{ width: "100%", marginTop: 1 }}
                                                    >
                                                        <TextField
                                                            id="address_section"
                                                            // label="Address Section"
                                                            multiline
                                                            rows={4}
                                                            name="address_section"
                                                            onChange={handleChange}
                                                            value={inputValue.address_section}
                                                        />
                                                    </FormControl>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Typography level="title-md">
                                                        {t("Top Logo (For best view 450x110px advised.)")}
                                                    </Typography>
                                                    <Button
                                                        variant="contained"
                                                        sx={{ marginTop: 1 }}
                                                        size="small"
                                                        component="label"
                                                        color="apimasters"
                                                    >
                                                        {t("Upload Logo")}
                                                        <input
                                                            type="file"
                                                            hidden
                                                            accept="image/*"
                                                            onChange={handleCapture}
                                                            id="top_logo"
                                                        />
                                                    </Button>
                                                    <img
                                                        alt="top logo"
                                                        src={
                                                            inputValue.top_logo ?? logo}
                                                        style={{ marginTop: 20, width: "100%" }}
                                                    />
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Typography level="title-md">
                                                        {t("Bottom Logo (For best view 225x55px advised.)")}
                                                    </Typography>
                                                    <Button
                                                        variant="contained"
                                                        sx={{ marginTop: 1 }}
                                                        size="small"
                                                        component="label"
                                                        id="footer_logo"
                                                        color="apimasters"
                                                    >
                                                        {t("Upload Logo")}
                                                        <input type="file"
                                                            hidden
                                                            accept="image/*"
                                                            id="footer_logo"
                                                            onChange={handleCapture} />
                                                    </Button>
                                                    <img
                                                        alt="footer logo"
                                                        src={inputValue.footer_logo ?? logo}
                                                        style={{ marginTop: 20, width: "100%" }}
                                                    />
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Typography level="title-md">
                                                        {t("Store Language")}
                                                    </Typography>
                                                    <FormControl>
                                                        <Select
                                                            value={inputValue.language ?? 'en'}
                                                            label={""}
                                                            id="language"
                                                            name="language"
                                                            color={"primary"}
                                                            sx={{ marginTop: 2 }}
                                                            size="small"
                                                            onChange={handleChange}>
                                                            <MenuItem value={'en'}>English</MenuItem>
                                                            <MenuItem value={'nl'}>Dutch</MenuItem>
                                                            <MenuItem value={'de'}>Deutsch</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Typography level="title-md">
                                                        {t("Shop Support Email")} *
                                                    </Typography>
                                                    <FormControl
                                                        sx={{ width: "100%", marginTop: 1 }}
                                                    >
                                                        <TextField
                                                            id="store_support_email"
                                                            // label="Title"
                                                            name="store_support_email"
                                                            onChange={handleChange}
                                                            value={inputValue.store_support_email}
                                                        />
                                                    </FormControl>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Container>

                                <Button
                                    variant="contained"
                                    sx={{ marginTop: 3, marginLeft: 3 }}
                                    size="small"
                                    onClick={saveSettings}
                                    disabled={saveButtonDisabled}
                                    color="apimasters"
                                >
                                    {t("Save")}
                                </Button>
                            </CustomTabPanel></>}
            </Box>
        </>
    );
}
