import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, FormControl, Input, InputLabel } from "@mui/material";
import axios from "../../axios";
import { useContext } from "react";
import { DataContext } from "../../App";
import { Trans, useTranslation } from "react-i18next";

export default function SetPasswordDialog({ handleClose, selectedAccount }) {
  const [inputValue, setInputValue] = React.useState({
    password: "",
    password_again: "",
  });
  const [isInApi, setIsInApi] = React.useState(false);
  const { setSnackbar } = useContext(DataContext);
  const { t } = useTranslation();

  const updatePassword = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setIsInApi(true);

    axios
      .put("/admin/accounts/" + selectedAccount.id + "/password", inputValue)
      .then(() => {
        handleClose();

        setSnackbar({
          message: t("Password successfully changed."),
          severity: "success",
          open: true,
        });
      })
      .catch(({ response }) => {
        setSnackbar({
          message:
            t(response.data?.message ||
              "An error occured while updating password."),
          severity: "error",
          open: true,
        });
      })
      .finally(() => {
        setIsInApi(false);
      });
  };

  const handleChange = (event) => {
    setInputValue((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value,
    }));
  };

  const selectedAccountName = selectedAccount.name;

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>
        <Trans i18nKey="set_password_for" selectedAccountName={selectedAccountName}>Set Password for {{ selectedAccountName }}</Trans>
        <span style={{ display: "none" }}>{selectedAccount.id}</span>
      </DialogTitle>
      <DialogContent>
        <Box>
          <FormControl
            sx={{ width: "100%", marginBottom: 2, marginTop: 1 }}
            required
          >
            <InputLabel htmlFor="password">{t("Password")}</InputLabel>
            <Input
              id="password"
              type="password"
              onChange={handleChange}
              value={inputValue.password}
            />
          </FormControl>
          <FormControl sx={{ width: "100%", marginBottom: 1 }} required>
            <InputLabel htmlFor="password_again">{t("Password Again")}</InputLabel>
            <Input
              id="password_again"
              type="password"
              onChange={handleChange}
              value={inputValue.password_again}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="apimasters">{t("Cancel")}</Button>
        <Button onClick={updatePassword} disabled={isInApi} color="apimasters">
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
