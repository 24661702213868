/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";
import styles from "./Styles.module.scss";
import {
    Breadcrumbs,
    Container,
    Grid,
    Link as BCLink,
    Box,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import axios from "../../axios";
import { numberFormatter } from "../../formatter";
import Quantity from "../../components/Quantity";
import MiniLoading from "../../components/MiniLoading";
import { calculateSpecialPrice } from "../../components/Cart";
import { useTranslation } from "react-i18next";

const Product = () => {
    const urlParams = useParams();
    const productID = urlParams.productID;
    const navigate = useNavigate();
    const [imageIndex, setImageIndex] = useState(0);
    const [revImgArr, setRevImgArr] = useState([]);
    const [bgPosition, setBgPosition] = useState("50% 50%");
    const [product, setProduct] = useState(null);

    useEffect(() => {
        fetchProduct(productID).then(function (res) {
            setProduct(res);
            setRevImgArr([res.PictureUrl]);
        });

        return () => {
            // setRevImgArr([product.PictureUrl]);
        };
    }, []);

    const fetchProduct = async (id) => {
        return await new Promise(function (resolve, reject) {
            axios
                .get("/products/" + id)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((err) => {
                    navigate("/");

                    reject(err);
                });
        });
    };

    const handleClick = (event) => {
        event.preventDefault();
        navigate(event.target.pathname);
    };

    const changeMainImage = useCallback((event) => {
        const selectedImgIndex = Number(
            event.currentTarget.getAttribute("imgindex")
        );
        setImageIndex(selectedImgIndex);
    }, []);

    const zoom = (e) => {
        const { left, top, width, height } = e.target.getBoundingClientRect();
        const x = ((e.clientX - left) / width) * 100;
        const y = ((e.clientY - top) / height) * 100;
        setBgPosition(`${x}% ${y}%`);
    };

    const { t } = useTranslation();

    return (
        <div>
            <Container sx={{ pb: "1rem" }}>
                <div role="presentation" onClick={handleClick}>
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        separator={<NavigateNext fontSize="small" />}
                        sx={{ p: "14px 0" }}
                    >
                        <BCLink
                            className={styles.brdHov}
                            underline="none"
                            color="inherit"
                            href="/"
                            fontSize={"14px"}
                        >
                            {t("Home")}
                        </BCLink>
                        <BCLink
                            className={styles.brdHov}
                            underline="none"
                            color="inherit"
                            href="/products"
                            fontSize={"14px"}
                        >
                            {t("Products")}
                        </BCLink>
                        <BCLink
                            underline="none"
                            color="text.primary"
                            href={window.location.href}
                            aria-current="page"
                            fontSize={"14px"}
                        >
                            {product == null ? "-" : product.Description}
                        </BCLink>
                    </Breadcrumbs>
                </div>
                {product !== null ? (
                    <Grid
                        container
                        spacing={2}
                        sx={{ justifyContent: "center" }}
                    >
                        <Grid item md={6} className={styles.left}>
                            <div className={styles.images}>
                                {revImgArr.map((item, index) => (
                                    <img
                                        onClick={changeMainImage}
                                        imgindex={index}
                                        className={
                                            index === imageIndex
                                                ? styles.activeImage
                                                : styles.notActive
                                        }
                                        src={item}
                                        key={index}
                                        alt={"images"}
                                    />
                                ))}
                            </div>
                            <div
                                className={styles.mainImage}
                                onMouseMove={zoom}
                                style={{
                                    backgroundImage: `url(${revImgArr[imageIndex]})`,
                                    backgroundPosition: bgPosition,
                                }}
                            >
                                <img
                                    src={revImgArr[imageIndex]}
                                    alt="mainImage"
                                />
                            </div>
                        </Grid>

                        <Grid item md={6}>
                            <div className={styles.right}>
                                <p
                                    style={{ margin: 0, padding: 0 }}
                                    className={styles.description}
                                >
                                    {t("SKU")}: <b>{product.Code ?? "-"}</b>
                                    <br />
                                    {t("Barcode")}:{" "}
                                    <b>{product.Barcode ?? "-"}</b>
                                </p>
                                <h1 style={{ margin: 0, padding: 0 }}>
                                    {product.Description}
                                </h1>
                                <p className={styles.price}>
                                    {calculateSpecialPrice(product) != null ? (
                                        <>
                                            <strike>
                                                {numberFormatter.format(
                                                    product.sales_item_price
                                                        .Price
                                                )}
                                            </strike>{" "}
                                            {numberFormatter.format(
                                                calculateSpecialPrice(product)
                                            )}
                                        </>
                                    ) : (
                                        numberFormatter.format(
                                            product.sales_item_price.Price
                                        )
                                    )}
                                </p>
                                <p className={styles.description}>
                                    {product.ExtraDescription}
                                </p>
                                <Box
                                    sx={{
                                        display: "flex",
                                        width: "30%",
                                        marginBottom: 2,
                                    }}
                                >
                                    <Quantity
                                        id={product.ID}
                                        sku={product.Code}
                                    />
                                </Box>
                                <div className={styles.end}>
                                    <p>
                                        {t("Item Group")}:{" "}
                                        {product.ItemGroupCode}
                                    </p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                ) : (
                    <MiniLoading />
                )}
            </Container>
        </div>
    );
};

export default React.memo(Product);
