import { Container, Grid } from "@mui/material";
import React from "react";
import axios from "../../axios";
import AddressDialog from "./AddressDialog";
import AccountDialog from "./AccountDialog";
import Accounts from "./AddressSelection/Accounts";
import Addresses from "./AddressSelection/Addresses";
import Contacts from "./AddressSelection/Contacts";

export default function AddressSelection({
  ordered,
  delivery,
  invoice,
  contacts,
  accounts,
  refreshAddresses,
  refreshAccountsAndContacts,
  inputValue,
  setInputValue,
  initialInputState,
}) {
  const [open, setOpen] = React.useState({ account: false, address: false });

  const handleChange = (event, type) => {
    if (event.target.id === "deliver_to_account") {
      setInputValue((prevState) => {
        return {
          ...prevState,
          [type]: {
            ...prevState[type],
            deliver_to_contact: event.target.contact,
          },
        };
      });
    } else if (event.target.id === "invoice_to_account") {
      setInputValue((prevState) => {
        return {
          ...prevState,
          [type]: {
            ...prevState[type],
            invoice_to_contact: event.target.contact,
          },
        };
      });
    }

    setInputValue((prevState) => {
      return {
        ...prevState,
        [type]: { ...prevState[type], [event.target.id]: event.target.value },
      };
    });
  };

  const onSave = (id, type, accountType = null) => {
    let field = "account";

    if (type === "ordered_by") {
      field = "ordered_by_contact";
    } else if (type === "invoice_to") {
      field = "invoice_to_contact";
    } else if (type === "deliver_to") {
      field = "deliver_to_contact";
    } else if (type === "account") {
      if (accountType === "delivery_account") {
        field = "deliver_to_account";
      } else {
        field = "invoice_to_account";
      }
    } else if (type === "address") {
      if (accountType === "ordered_by") {
        field = "ordered_by_address";
      } else if (accountType === "delivery_to") {
        field = "delivery_address";
      } else {
        field = "invoice_to_address";
      }
    }

    setInputValue((prevState) => {
      return {
        ...prevState,
        main: { ...prevState.main, [field]: id },
      };
    });
  };

  const handleOpenAddressDialog = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setInputValue((prevState) => ({
      ...prevState,
      address: {
        address_type: e.target.getAttribute("address-type"),
      },
    }));

    if (e.target.id.length !== 0) {
      getAddress(e.target.id).then((address) => {
        setInputValue((prevState) => ({
          ...prevState,
          address: {
            address_id: address.ID,
            address_line_1: address.AddressLine1 ?? "",
            address_line_2: address.AddressLine2 ?? "",
            address_line_3: address.AddressLine3 ?? "",
            address_phone: address.Phone ?? "",
            address_postcode: address.PostCode ?? "",
            address_country: address.Country.trim() ?? "",
            address_city: address.City ?? "",
            address_type: e.target.getAttribute("address-type"),
          },
        }));

        setOpen((prevState) => {
          return { ...prevState, address: true };
        });
      });
    } else {
      setOpen((prevState) => {
        return { ...prevState, address: true };
      });
    }

    setInputValue((prevState) => ({
      ...prevState,
      address: {
        ...initialInputState.address,
        address_type: e.target.getAttribute("address-type"),
      },
    }));
  };
  const handleClose = () => setOpen(false);

  const handleOpenAccountDialog = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setInputValue((prevState) => ({
      ...prevState,
      account: {
        ...initialInputState.account,
        entity_type: "account",
        account_type: e.target.getAttribute("account-type"),
      },
    }));

    if (e.target.id.length !== 0) {
      let account = JSON.parse(e.target.getAttribute("data-row"));

      setInputValue((prevState) => ({
        ...prevState,
        account: {
          ...prevState.account,
          entity_id: e.target.id,
          name: account.Name ?? "",
          address_line_1: account.AddressLine1 ?? "",
          address_line_2: account.AddressLine2 ?? "",
          address_line_3: account.AddressLine3 ?? "",
          address_phone: account.Phone ?? "",
          address_postcode: account.PostCode ?? "",
          address_country: account.Country.trim() ?? "",
          address_city: account.City ?? "",
          address_type: account.Type,
        },
      }));

      setOpen((prevState) => {
        return { ...prevState, account: true };
      });
    } else {
      setOpen((prevState) => {
        return { ...prevState, account: true };
      });
    }
  };

  const handleOpenContactDialog = (e, contact_type) => {
    e.stopPropagation();
    e.preventDefault();

    let accountId = null;

    if (contact_type === "ordered_by") {
      accountId = inputValue.main.ordered_by_account;
    } else if (contact_type === "deliver_to") {
      accountId = inputValue.main.deliver_to_account;
    } else if (contact_type === "invoice_to") {
      accountId = inputValue.main.invoice_to_account;
    }

    setInputValue((prevState) => ({
      ...prevState,
      account: {
        ...initialInputState.account,
        account_id: accountId,
        entity_type: contact_type,
      },
    }));

    if (e.target.id.length !== 0) {
      let account = JSON.parse(e.target.getAttribute("data-row"));

      setInputValue((prevState) => ({
        ...prevState,
        account: {
          ...prevState.account,
          entity_id: e.target.id,
          account_id: accountId,
          first_name: account.FirstName ?? "",
          last_name: account.LastName ?? "",
          e_mail: account.Email ?? "",
          phone: account.Phone ?? "",
          mobile_phone: account.MobilePhone ?? "",
        },
      }));

      setOpen((prevState) => {
        return { ...prevState, account: true };
      });
    } else {
      setOpen((prevState) => {
        return { ...prevState, account: true };
      });
    }
  };

  const getAddress = async (id) => {
    return await new Promise(function (resolve, reject) {
      axios
        .get("/my-account/addresses/" + id)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch(reject);
    });
  };

  return (
    <Container sx={{ padding: "0px!important" }}>
      <AddressDialog
        refresh={refreshAddresses}
        open={open.address}
        handleClose={handleClose}
        handleChange={(e) => {
          handleChange(e, "address");
        }}
        inputValue={inputValue}
        setOpen={setOpen}
        onSave={onSave}
      />
      <AccountDialog
        refresh={refreshAccountsAndContacts}
        open={open.account}
        handleClose={handleClose}
        handleChange={(e) => {
          handleChange(e, "account");
        }}
        inputValue={inputValue.account}
        inputValues={inputValue}
        setOpen={setOpen}
        onSave={onSave}
      />
      <Grid
        container
        justify="space-around"
        spacing={2}
        sx={{
          marginBottom: 3,
        }}
      >
        <Accounts
          accounts={accounts}
          inputValue={inputValue}
          handleChange={(e) => {
            handleChange(e, "main");
          }}
          handleOpenAccountDialog={handleOpenAccountDialog}
        />
        <Contacts
          contacts={contacts}
          inputValue={inputValue}
          handleChange={(e) => {
            handleChange(e, "main");
          }}
          handleOpenContactDialog={handleOpenContactDialog}
        />
        <Addresses
          ordered={ordered}
          invoice={invoice}
          delivery={delivery}
          inputValue={inputValue}
          handleChange={handleChange}
          handleOpenAddressDialog={handleOpenAddressDialog}
        />
      </Grid>
    </Container>
  );
}
