/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./Styles.module.scss";
import {
  Breadcrumbs,
  Container,
  Grid,
  Link as BCLink,
  Box,
  Pagination,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import axios from "../../axios";
import SearchBox from "../../components/Navbar/SearchBox";
import LogsTable from "../../components/LogsTable";
import { useTranslation } from "react-i18next";

const Logs = () => {
  const [logs, setLogs] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setLogs(null);
      fetch();
    }, 500);

    return () => clearTimeout(timer);
  }, [searchValue]);

  const fetch = () => {
    fetchLogs().then(function (res) {
      setLogs(res);
    });
  };

  const fetchLogs = async (page) => {
    return await new Promise(function (resolve, reject) {
      axios
        .get("/admin/store/logs?page=" + (page ?? 0) + "&search=" + searchValue)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch(reject);
    });
  };

  const refresh = (event, page = null) => {
    setLogs(null);
    fetchLogs(page).then(function (res) {
      setLogs(res);
    });
  };

  const { t } = useTranslation();

  return (
    <div>
      <Container sx={{ pb: "1rem" }}>
        <div role="presentation">
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNext fontSize="small" />}
            sx={{ p: "14px 0" }}
          >
            <BCLink
              className={styles.brdHov}
              underline="none"
              color="inherit"
              href="/"
              fontSize={"14px"}
            >
              {t("Home")}
            </BCLink>
            <BCLink
              underline="none"
              color="text.primary"
              href="/store/logs"
              aria-current="page"
              fontSize={"14px"}
            >
              {t("Logs")}
            </BCLink>
          </Breadcrumbs>
        </div>
        <Grid container item spacing={2} sx={{ justifyContent: "center" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <SearchBox
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              placeholder={t("Search crons")}
              extraStyle={{ margin: "0px 0px 20px 0px" }}
              navigateOnEnter={null}
            />
          </Box>
          <Grid container item>
            <LogsTable data={logs} searchValue={searchValue} />
            <Container
              sx={{
                marginTop: 2,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                count={logs?.last_page || 0}
                page={logs?.current_page || 0}
                onChange={refresh}
              />
            </Container>
            {/* </> */}
            {/* )} */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default React.memo(Logs);
