import React from "react";
import { Box, Button, IconButton, TextField } from "@mui/material";
import { HiPlus, HiMinus } from "react-icons/hi";
import { useState } from "react";
import axios from "../axios";
import { DataContext } from "../App";
import { useContext } from "react";
import { useEffect } from "react";
import i18next, { t } from "i18next";
import { useTranslation } from "react-i18next";

export default function Quantity({
  id,
  sku,
  style,
  isOrderPage = false,
  isCartPage = false,
}) {
  const { cartList, setCartList, setSnackbar } = useContext(DataContext);
  const [quantity, setQuantity] = useState(0);
  const [isInApi, setIsInApi] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOrderPage === false) {
      let product = cartList.find((cartItem) => cartItem.id === id);

      if (product) {
        setQuantity(product.quantity);
      } else {
        setQuantity(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartList]);

  const addToBasket = (relative) => {
    changeAmount(
      quantity <= 0 && relative === true ? +1 : +quantity,
      id,
      relative
    );
  };

  const changeAmount = (amount, id, relative = true) => {
    if (isCartPage === true && amount === 0) {
      let array = cartList;
      const productIndex = array.findIndex((item) => item.id === id);
      array.splice(productIndex, 1);
      setCartList((prevState) => [...array]);

      axios.delete("/cart/" + id).then((response) => { });
      return;
    }

    let snack = () => {
      if (amount > 0) {
        setSnackbar({
          // t("Added") + " " + amount + " " + t("pieces of") + " " + sku + t(" to basket.")
          message: i18next.t('added_to_cart', { amount: amount, sku: sku }),
          severity: "success",
          open: true,
        });
      } else {
        setSnackbar({
          message:
            i18next.t('removed_from_cart', { amount: Math.abs(amount), sku: sku }),
          severity: "error",
          open: true,
        });
      }
    };

    if (
      cartList.some(
        (cartItem) => cartItem.id === id && cartItem.quantity + amount >= 1
      )
    ) {
      let isHandled = false;

      setCartList((cart) =>
        cart.map((cartItem) => {
          if (cartItem.id === id && !isHandled) {
            setIsInApi(true);

            axios
              .post("/cart", {
                id: id,
                quantity:
                  isCartPage && relative === false
                    ? parseInt(amount)
                    : parseInt(cartItem.quantity) + parseInt(amount),
              })
              .then((response) => {
                setIsInApi(false);
                snack();

                if (isOrderPage === true) {
                  setQuantity((prevQuantity) => {
                    return parseInt(prevQuantity) + parseInt(amount);
                  });
                }
              })
              .catch(({ response }) => {
                setIsInApi(false);

                setSnackbar({
                  message:
                    t(response.data?.message) ||
                    t("An error occured while adding or removing from basket."),
                  severity: "error",
                  open: true,
                });
              });

            isHandled = true;
          }

          return cartItem.id === id
            ? {
              ...cartItem,
              quantity:
                isCartPage && relative === false
                  ? parseInt(amount)
                  : parseInt(cartItem.quantity) + parseInt(amount),
            }
            : cartItem;
        })
      );
    } else {
      setIsInApi(true);

      axios
        .post("/cart", {
          id: id,
          quantity: amount,
        })
        .then((response) => {
          if (isOrderPage === true) {
            setQuantity((prevQuantity) => {
              return parseInt(prevQuantity) + parseInt(amount);
            });
          }
          setIsInApi(false);

          setCartList(Object.values(response.data.cart));
          snack();
        })
        .catch(({ response }) => {
          setIsInApi(false);

          setSnackbar({
            message:
              response.data?.message ||
              t("An error occured while adding or removing from basket."),
            severity: "error",
            open: true,
          });
        });
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...style,
        }}
      >
        <IconButton
          color="primary"
          component="label"
          sx={{ color: "black" }}
          size="sm"
          onClick={() => setQuantity((prevState) => parseInt(prevState) - 1)}
          // onClick={() => changeAmount(-1, id)}
          disabled={isInApi || quantity <= 0}
        >
          <HiMinus />
        </IconButton>
        <TextField
          type="text"
          onChange={(e) => {
            const onlyNums = e.target.value.replace(/[^0-9]/g, "");

            if (onlyNums.length < 10) {
              setQuantity(onlyNums === "" ? "0" : onlyNums);
            } else if (onlyNums.length === 10) {
              const number = onlyNums.replace(
                /(\d{3})(\d{3})(\d{4})/,
                "($1) $2-$3"
              );

              setQuantity(number === "" ? "0" : number);
            }
          }}
          InputLabelProps={{
            shrink: true,
          }}
          value={quantity}
          sx={{ input: { textAlign: "center", width: "100%" } }}
          size="sm"
        // disabled={isInApi}
        />
        <IconButton
          component="label"
          sx={{ color: "black" }}
          size="sm"
          onClick={() => setQuantity((prevState) => parseInt(prevState) + 1)}
          // onClick={() => changeAmount(+1, id)}
          disabled={isInApi}
        >
          <HiPlus />
        </IconButton>
      </Box>
      <Box
        sx={{
          marginTop: 1,
          marginBottom: 1,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            addToBasket(!isCartPage);
          }}
          disabled={isInApi}
          color="apimasters"
          sx={{
            minWidth: '180px',
            padding: '5px 5px'
          }}
        >
          {isCartPage ? t("Update") : t("Add to Cart")}
        </Button>
      </Box>
    </Box>
  );
}
