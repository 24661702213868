/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, Grid, Link } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Styles.module.scss";
import { DataContext } from "../../App";
// import { validateForgotPassword, validateSignIn } from "../Handle";
import axios from "../../axios";
import Auth from "../../Auth";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { blue } from "@mui/material/colors";
import BusinessIcon from "@mui/icons-material/Business";
import { useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const steps = [
    {
        name: "Company information",
        description: "step1_description",
        component: <Step1 />,
    },
    {
        name: "User credentials",
        description: "step2_description",
        component: <Step2 />,
    },
    {
        name: "Exact authentication",
        description: "step3_description",
        component: <Step3 />,
    },
    {
        name: "Confirmation",
        description: "",
        component: <Step4 />,
    },
];

export default function Register() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [redirected, setRedirected] = React.useState(false);
    const [skipped, setSkipped] = React.useState(new Set());
    const [nextButtonDisabled, setNextButtonDisabled] = React.useState(false);
    const { setAuth } = useContext(DataContext);
    const { setSnackbar } = useContext(DataContext);
    const { t } = useTranslation();

    let query = useQuery();

    const isStepOptional = (step) => {
        return false;
        return step === 2;
    };

    useEffect(() => {
        if (
            (query.get("auth_id") != null || Auth.isAuthenticated()) &&
            !redirected
        ) {
            setActiveStep(2);
            setNextButtonDisabled(true);
            setRedirected(true);
        }
    });

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        if (activeStep === 1) {
            setNextButtonDisabled(true);
            axios
                .post("/exact/store/register", inputValue)
                .then((response) => {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setNextButtonDisabled(false);

                    setAuth({ currentUser: response.data.user });
                    Auth.setUser(response.data.user);
                    Auth.setToken(response.data.token.plainTextToken);

                    window.location.reload();
                })
                .catch(({ response }) => {
                    setNextButtonDisabled(false);
                    console.log(response);
                    setSnackbar({
                        message: t(response.data.message),
                        severity: "error",
                        open: true,
                    });
                });
            return;
        }

        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [inputValue, setInputValue] = useState({
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        company: "",
    });

    const [touched, setTouched] = useState({});

    const handleChange = (event) => {
        setInputValue((prevState) => ({
            ...prevState,
            [event.target.id]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value,
        }));
    };

    useEffect(() => {
        // setErrors(validateSignIn(inputValue));
    }, [inputValue, touched]);

    const focusHandler = (event) => {
        setTouched({ ...touched, [event.target.id]: true });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Container sx={{ marginTop: 4, marginBottom: 2, width: "100%" }}>
            <Grid
                container
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                }}
            >
                <Grid
                    container
                    item
                    sm={10}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <Box>
                        <Box sx={{ width: "100%" }}>
                            <Stepper
                                activeStep={activeStep}
                                sx={{
                                    marginBottom: 4,
                                    "& .MuiStepIcon-root": {
                                        color: "var(--primary)",
                                    },
                                }}
                            >
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    if (isStepOptional(index)) {
                                        labelProps.optional = (
                                            <Typography variant="caption">
                                                {t("Optional")}
                                            </Typography>
                                        );
                                    }
                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step
                                            key={index}
                                            {...stepProps}
                                            color="apimasters"
                                        >
                                            {/* 
                      t("Company information")
 t("User credentials")
 t("Exact authentication")
                       */}
                                            <StepLabel {...labelProps}>
                                                {t(label.name)}
                                            </StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            {activeStep === steps.length ? (
                                <React.Fragment>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        {t(
                                            "All steps completed - you&apos;re finished"
                                        )}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            pt: 2,
                                        }}
                                    >
                                        <Box sx={{ flex: "1 1 auto" }} />
                                        <Button onClick={handleReset}>
                                            {t("Reset")}
                                        </Button>
                                    </Box>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div style={{ marginBottom: "13px" }}>
                                        {t(steps[activeStep].description)}
                                    </div>
                                    <Box>
                                        {React.cloneElement(
                                            steps[activeStep].component,
                                            {
                                                setActiveStep: setActiveStep,
                                                inputValue: inputValue,
                                                handleChange: handleChange,
                                                focusHandler: focusHandler,
                                                handleNext: handleNext,
                                            }
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            pt: 2,
                                        }}
                                    >
                                        {activeStep === 1 && (
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                sx={{ mr: 1 }}
                                            >
                                                {t("Back")}
                                            </Button>
                                        )}
                                        <Box sx={{ flex: "1 1 auto" }} />
                                        {/* {isStepOptional(activeStep) && (
                      <Button
                        color="inherit"
                        onClick={handleSkip}
                        sx={{ mr: 1 }}
                      >
                        Skip
                      </Button>
                    )} */}

                                        {activeStep !== steps.length - 1 && (
                                            <Button
                                                onClick={handleNext}
                                                disabled={nextButtonDisabled}
                                                color="apimasters"
                                            >
                                                {t("Next")}
                                            </Button>
                                        )}
                                    </Box>
                                </React.Fragment>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export function Step2({
    inputValue,
    focusHandler,
    handleChange,
    errors,
    touched,
    handleNext,
}) {
    const { t } = useTranslation();

    return (
        <>
            <div className={styles.inputs} style={{ marginBottom: "13px" }}>
                <div>
                    <label htmlFor="email">{t("E-mail")} *</label>
                </div>
                <input
                    defaultValue={inputValue.email}
                    onChange={handleChange}
                    onFocus={focusHandler}
                    type="email"
                    id="email"
                />
                <div>
                    {errors?.email && touched.email && <p>{errors.email}</p>}
                </div>
            </div>
            <div className={styles.inputs} style={{ marginBottom: "13px" }}>
                <div>
                    <label htmlFor="password">{t("Password")} *</label>
                </div>
                <input
                    defaultValue={inputValue.password}
                    onChange={handleChange}
                    onFocus={focusHandler}
                    type="password"
                    id="password"
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleNext();
                        }
                    }}
                />
                <div>
                    {errors?.password && touched.password && (
                        <p>{errors.password}</p>
                    )}
                </div>
            </div>
        </>
    );
}

export function Step1({
    inputValue,
    focusHandler,
    handleChange,
    errors,
    touched,
    handleNext,
}) {
    const { t } = useTranslation();

    return (
        <>
            <div className={styles.inputs} style={{ marginBottom: "13px" }}>
                <div>
                    <label htmlFor="first_name">{t("First Name")}</label>
                </div>
                <input
                    defaultValue={inputValue.first_name}
                    onChange={handleChange}
                    onFocus={focusHandler}
                    type="text"
                    id="first_name"
                />
                <div>
                    {errors?.first_name && touched.first_name && (
                        <p>{errors.first_name}</p>
                    )}
                </div>
            </div>
            <div className={styles.inputs} style={{ marginBottom: "13px" }}>
                <div>
                    <label htmlFor="last_name">{t("Last Name")}</label>
                </div>
                <input
                    defaultValue={inputValue.last_name}
                    onChange={handleChange}
                    onFocus={focusHandler}
                    type="last_name"
                    id="last_name"
                />
                <div>
                    {errors?.last_name && touched.last_name && (
                        <p>{errors.last_name}</p>
                    )}
                </div>
            </div>

            <div className={styles.inputs} style={{ marginBottom: "13px" }}>
                <div>
                    <label htmlFor="phone">{t("Phone")}</label>
                </div>
                <input
                    defaultValue={inputValue.phone}
                    onChange={handleChange}
                    onFocus={focusHandler}
                    type="phone"
                    id="phone"
                />
                <div>
                    {errors?.phone && touched.phone && (
                        <p>{errors.last_name}</p>
                    )}
                </div>
            </div>

            <div className={styles.inputs} style={{ marginBottom: "13px" }}>
                <div>
                    <label htmlFor="company">{t("Company Name")}</label>
                </div>
                <input
                    defaultValue={inputValue.company}
                    onChange={handleChange}
                    onFocus={focusHandler}
                    type="company"
                    id="company"
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleNext();
                        }
                    }}
                />
                <div>
                    {errors?.company && touched.company && (
                        <p>{errors.company}</p>
                    )}
                </div>
            </div>
        </>
    );
}

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function Step3({ setActiveStep }) {
    let query = useQuery();

    const [authDetails, setAuthDetails] = useState(null);
    const [divisions, setDivisions] = useState([]);
    const [showDivisionDialog, setShowDivisionDialog] = useState(false);
    const { setSnackbar } = useContext(DataContext);
    const { t } = useTranslation();
    useEffect(() => {
        axios
            .get(
                "/exact/auth-details?url=" +
                    window.location.protocol +
                    "//" +
                    window.location.host
            )
            .then((response) => {
                setAuthDetails(response.data);

                if (
                    response.data != null &&
                    response.data?.has_auth !== false
                ) {
                    setActiveStep(3);
                }
            });

        if (query.get("show_divisions")) {
            setShowDivisionDialog(true);

            axios
                .get("/exact/get-all-divisions/" + query.get("auth_id"))
                .then((response) => {
                    setDivisions(response.data.data);
                })
                .catch(({ response }) => {
                    setSnackbar({
                        message: t(
                            "An error occured while loading divisions. Maybe re-authorizating will fix it or you don't have permission to list divisions."
                        ),
                        severity: "error",
                        open: true,
                    });
                    setShowDivisionDialog(false);
                });
        }
    }, []);

    const company = Auth.getUser().auth?.company_name ?? "-";
    const connectedTranslation = t("connected");
    const notConnectedTranslation = t("not connected");
    return (
        <>
            {showDivisionDialog && (
                <SimpleDialog
                    open={true}
                    divisions={divisions}
                    setShowDivisionDialog={setShowDivisionDialog}
                />
            )}
            {company !== "-" &&
            authDetails != null &&
            authDetails?.has_auth !== false ? (
                <Box style={{ whiteSpace: "pre-line" }}>
                    {t("step4_description")}
                </Box>
            ) : (
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <div>
                        <Button
                            component={Link}
                            href={
                                authDetails != null ? authDetails.auth_url : ""
                            }
                            variant="contained"
                            color="apimasters"
                            disabled={authDetails == null}
                        >
                            {t("Connect to exact")}
                        </Button>
                    </div>
                    {/* <div style={{ marginTop: 10, textAlign: "center" }}>
                        {authDetails != null && (
                            <>
                                {authDetails.has_auth ? (
                                    <span>
                                        <Trans
                                            i18nKey="you_are_connected"
                                            connectedTranslation={
                                                connectedTranslation
                                            }
                                        >
                                            You are{" "}
                                            <b style={{ color: "green" }}>
                                                {{ connectedTranslation }}
                                            </b>{" "}
                                            to Exact Online.
                                        </Trans>
                                    </span>
                                ) : (
                                    <span>
                                        <Trans
                                            i18nKey="you_are_not_connected"
                                            notConnectedTranslation={
                                                notConnectedTranslation
                                            }
                                        >
                                            You are{" "}
                                            <b style={{ color: "red" }}>
                                                {{ notConnectedTranslation }}
                                            </b>{" "}
                                            to Exact Online.
                                        </Trans>
                                    </span>
                                )}
                            </>
                        )}
                        <br />
                        {company !== "-" &&
                            authDetails != null &&
                            authDetails?.has_auth !== false && (
                                <Trans
                                    i18nKey="authenticated_to_which_company"
                                    company={company}
                                >
                                    Authenticated to <b>{{ company }}</b>{" "}
                                    company.
                                </Trans>
                            )}
                    </div> */}
                </Box>
            )}
        </>
    );
}

export function Step4() {
    const { t } = useTranslation();

    return (
        <>
            <Box
                style={{ whiteSpace: "pre-line" }}
                dangerouslySetInnerHTML={{ __html: t("step4_description") }}
            ></Box>
        </>
    );
}

function SimpleDialog(props) {
    const { open, divisions, setShowDivisionDialog } = props;
    const [disabled, setDisabled] = useState(false);
    const { setSnackbar } = useContext(DataContext);
    const { t } = useTranslation();

    const handleListItemClick = (value) => {
        // onClose(value);
        setDisabled(true);

        console.log(value);
        axios
            .post("/exact/select-division", {
                division: value.Code,
            })
            .then((response) => {
                setDisabled(false);
                setShowDivisionDialog(false);

                let user = JSON.parse(localStorage.getItem("user"));
                user.store_division = value.Code;
                if (typeof user?.auth != "object") {
                    user.auth = {};
                }
                user.auth.company_name = response.data?.data?.company_name;
                user.subscription_end_date =
                    response.data?.data?.subscription_end_date;
                localStorage.setItem("user", JSON.stringify(user));

                window.location.href = "/try-now";
            })
            .catch(({ response }) => {
                setDisabled(false);
                setSnackbar({
                    message: t(response.data.message),
                    severity: "error",
                    open: true,
                });
            });
        //
    };

    return (
        <Dialog open={open}>
            <DialogTitle>{t("Select Division")}</DialogTitle>
            <List sx={{ pt: 0 }}>
                {divisions.map((row) => (
                    <ListItem disableGutters key={row.Hid}>
                        <ListItemButton
                            onClick={() => handleListItemClick(row)}
                            disabled={disabled}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        bgcolor: blue[100],
                                        color: blue[600],
                                    }}
                                >
                                    <BusinessIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={row.Description}
                                sx={{ marginRight: 2 }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
                {divisions.length === 0 && (
                    <ListItem disableGutters>
                        <ListItemButton>
                            <ListItemAvatar>
                                {/* <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
              <BusinessIcon />
            </Avatar> */}
                            </ListItemAvatar>
                            <ListItemText
                                primary={t("Loading..")}
                                sx={{ marginRight: 2 }}
                            />
                        </ListItemButton>
                    </ListItem>
                )}
            </List>
        </Dialog>
    );
}
