const countries = [
  { value: "AT", name: "Austria" },
  { value: "BE", name: "Belgium" },
  { value: "CH", name: "Switzerland" },
  { value: "DE", name: "Germany" },
  { value: "DK", name: "Denmark" },
  { value: "ES", name: "Spain" },
  { value: "FR", name: "France" },
  { value: "GB", name: "United Kingdom" },
  { value: "IE", name: "Ireland" },
  { value: "IT", name: "Italy" },
  { value: "LT", name: "Lithuania" },
  { value: "LU", name: "Luxembourg" },
  { value: "MT", name: "Malta" },
  { value: "NL", name: "The Netherlands" },
  { value: "PT", name: "Portugal" },
  { value: "RO", name: "Romania" },
  { value: "RS", name: "Serbia" },
  { value: "TR", name: "Turkey" },
].sort((a, b) => a.name.localeCompare(b.name));

export default countries;
