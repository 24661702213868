import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "../../axios";
import MiniLoading from "../MiniLoading";
// import SetPasswordDialog from "./SetPasswordDialog";
import Auth from "../../Auth";
import { useTranslation } from "react-i18next";

const Row = ({ data, rows }) => {
  const [categoryLink, setCategoryLink] = React.useState(data.parent?.parent_category_id ?? 'not-selected');
  const [isInApi, setIsInApi] = React.useState(false);
  const { t } = useTranslation();

  const changeCategoryLink = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setCategoryLink((prevState) => {
      setIsInApi(true);

      axios
        .post(
          "/categories",
          {
            category_id: data.ID,
            parent_id: e.target.value
          }
        )
        .catch(() => {
          setCategoryLink(prevState);
        })
        .finally(() => {
          setIsInApi(false);
        });

      return e.target.value;
    });
  };

  return (
    <TableRow
      key={data.ID}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        {data.Code}
      </TableCell>
      <TableCell>{data.Description}</TableCell>
      <TableCell>{data.Notes ?? "-"}</TableCell>
      <TableCell>
        {/* <Checkbox
          color="apimasters"
          checked={canOrder}
          inputProps={{
            "data-id": data.ID,
          }}
          onChange={changeCanOrder}
          disabled={isInApi}
        /> */}
        <FormControl fullWidth onClick={(e) => e.stopPropagation()}>
          <InputLabel id="category-select-label">{t("Linked to")}</InputLabel>
          <Select
            value={categoryLink}
            labelId="category-select-label"
            label={t("Linked to")}
            onChange={changeCategoryLink}
            disabled={isInApi}
          >
            <MenuItem value={'not-selected'}>{t("Not linked")}</MenuItem>
            {rows?.filter((category) => {
              return category.ID !== data.ID;
            }).map((category) => (
              <MenuItem value={category.ID}>{category.Description}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      {/* <TableCell>
        <Button
          color="apimasters"
          variant="contained"
          data-id={data.ID}
          data-email={data.Email}
          onClick={sendPasswordMail}
          disabled={
            isInApi ||
            (data.Email != null && data.Email.trim() === "") ||
            data.Email == null
          }
          style={{
            color: "white",
          }}
        >
          SendPasswordMail
        </Button>
      </TableCell>

      <TableCell>
        <Button
          color="apimasters"
          variant="contained"
          data-id={data.ID}
          data-name={data.Name + " (" + data.Code.trim() + ")"}
          onClick={handleOpenPasswordDialog}
          disabled={isInApi}
          style={{
            color: "white",
          }}
        >
          SetPassword
        </Button>
      </TableCell> */}
    </TableRow>
  );
};

export default function CategoriesTable(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedAccount, setSelectedAccount] = React.useState(null);
  const { t } = useTranslation();

  const handleOpenPasswordDialog = (e) => {
    setSelectedAccount({
      id: e.target.getAttribute("data-id"),
      name: e.target.getAttribute("data-name"),
    });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const Alert = (props) => (
    <TableRow>
      <TableCell colSpan={4} sx={{ textAlign: "center" }}>
        {props.message}
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {/* {open && (
        <SetPasswordDialog
          handleClose={handleClose}
          selectedAccount={selectedAccount}
        />
      )} */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>{t("Code")}</TableCell>
              <TableCell>{t("Description")}</TableCell>
              <TableCell>{t("Notes")}</TableCell>
              {/* <TableCell></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data == null ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <MiniLoading />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {Auth.getUser().store_division == null ? <Alert message={t("First please do auth at Authentication page.")} /> :
                  props.data.data.length === 0 && <Alert message={t("There are no categories.")} />}
                {Auth.getUser().store_division != null
                  && props.data.data?.map((row) => (
                    <Row
                      key={row.ID}
                      data={row}
                      rows={props.data.data}
                      handleOpenPasswordDialog={handleOpenPasswordDialog}
                    />
                  ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}


