import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { lazy, Suspense, forwardRef } from "react";
import Loading from "./components/Loading";
import { useState, createContext, useEffect } from "react";
import Footer from "./components/Footer/Footer";
import { Route, Routes } from "react-router-dom";
import WishList from "./pages/Wishlist/Wishlist";
// import { onAuthStateChanged } from "firebase/auth";
import MuiAlert from "@mui/material/Alert";
import { Snackbar, Fade } from "@mui/material";
import Cart from "./pages/Cart/Cart";
import Product from "./pages/Product/Product";
import Auth from "./Auth";
import Products from "./pages/Products/Products";
import Orders from "./pages/MyAccount/Orders";
import Invoices from "./pages/MyAccount/Invoices";
import InvoiceDetails from "./pages/MyAccount/InvoiceDetails";
import OrderDetails from "./pages/MyAccount/OrderDetails";
import Accounts from "./pages/Admin/Accounts";
import Categories from "./pages/Admin/Categories";
import ResetPassword from "./pages/Auth/ResetPassword";
import { getUserCart } from "./components/Cart";
import ThanksForOrder from "./pages/Cart/ThanksForOrder";
import MostPurchasedProducts from "./pages/MyAccount/MostPurchasedProducts";
import TopNavBar from "./components/Navbar/TopNavBar";
import NotFound from "./pages/NotFound";
import Register from "./pages/Auth/Register";
import Settings from "./pages/Auth/Settings";
import StoreLogin from "./pages/Auth/StoreLogin";
import axios from "./axios";
import MyAccount from "./pages/MyAccount/MyAccount";
import Login from "./pages/Customer/Login";
import Logs from "./pages/Admin/Logs";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Crons from "./pages/Admin/Crons";
import 'moment/min/locales'
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const Home = lazy(() => import("./pages/Home/Home"));
const Navbar = lazy(() => import("./components/Navbar/Navbar"));
export const DataContext = createContext();

i18n
  .use(initReactI18next)
  .init({
    resources: {
      ...window.translations
      // nl: {
      //   translation: {
      //     "subscription_left": "Subscription ends/renews after <1>{{ daysLeft }}</1> days.",
      //     "frequent": "Every {{frequent}} minutes",
      //     "account_sync_information": "If you cannot find the Account you are looking for, the system will sync after {{ nextSync }}, please check again after {{ nextSync }}.",
      //     "authenticated_to_which_company": "Authenticated to <1>{{ company }}</1> company.",
      //     "you_are_connected": "You are <1>{{ connectedTranslation }}</1> to Exact Online."
      //   }
      // }
    },
    lng: localStorage.getItem("lang") ?? window.storeSettings.language,
    fallbackLng: "nl",

    interpolation: {
      escapeValue: false
    }
  });

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const [wishList, setWishList] = useState([]);
  const [cartList, setCartList] = useState([]);
  const [auth, setAuth] = useState([]);
  const [themeColors, setThemeColors] = useState({
    primary_color: window.storeSettings.primary_color,
    secondary_color: window.storeSettings.secondary_color
  });
  const [searchValue, setSearchValue] = useState("");
  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    severity: "",
  });

  useEffect(() => {
    setAuth({
      currentUser: Auth.getUser(),
    });
    initTheme();
  }, []);

  useEffect(() => {
    let getCart = async () => {
      if (Auth.isAuthenticated()) {
        getUserCart().then(function (cart) {
          setCartList([...cart]);
        });
      }
    };

    getCart();
  }, [auth]);

  function initTheme() {
    if (!Auth.isAuthenticated()) return;

    let division = JSON.parse(localStorage.getItem('user'))?.Division ??
      JSON.parse(localStorage.getItem('user'))?.store_division;

    if (typeof division === "undefined" || division == null) return;

    let currentTheme = JSON.parse(localStorage.getItem('currentStoreTheme'));

    if (currentTheme == null) {
      axios.get("/exact/store/details/" + division).then(({ data }) => {
        window.storeSettings = data.data;

        loadTheme(window.storeSettings);

        localStorage.setItem('currentStoreTheme', JSON.stringify(window.storeSettings));

      });
    } else {
      window.storeSettings = currentTheme;

      loadTheme(currentTheme);
    }

  }

  function loadTheme(theme) {
    document.title = theme?.site_name ?? 'API Masters';
    document.querySelector('meta[name="description"]').setAttribute("content", theme?.site_description ?? 'API Masters');
    document.documentElement.style.setProperty('--primary', theme?.primary_color ?? '#2b749b');
    document.documentElement.style.setProperty('--secondary', theme?.secondary_color ?? '#2b749b');

    setThemeColors({
      primary_color: theme?.primary_color ?? '#2b749b',
      secondary_color: theme?.secondary_color ?? '#2b749b',
    });
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar({ ...snackbar, open: !snackbar.open });
  };

  var theme = createTheme({
    palette: {
      apimasters: createColor(themeColors.primary_color),
      primary: {
        main: createColor(themeColors.primary_color).main,
      },
      secondary: {
        main: createColor(themeColors.primary_color).main,
        // main: createColor(themeColors.secondary_color).main,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
    },
  });

  return (
    <DataContext.Provider
      value={{
        auth,
        setAuth,
        wishList,
        setWishList,
        cartList,
        setCartList,
        snackbar,
        setSnackbar,
        searchValue,
        setSearchValue,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<Loading />}>
          <TopNavBar />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/wishlist" element={<WishList />} />
            <Route path="/products/:productID" element={<Product />} />
            <Route path="/products" element={<Products />} />
            <Route path="/category/:categoryID" element={<Products />} />
            {Auth.isAuthenticated() && <>
              <Route path="/cart" element={<Cart />} />
              <Route
                path="/cart/thanks-for-order/:order_number"
                element={<ThanksForOrder />}
              />
              <Route path="/my-account" element={<MyAccount />} />
              <Route
                path="/my-account/most-purchased-products"
                element={<MostPurchasedProducts />}
              />
              <Route path="/my-account/orders" element={<Orders />} />
              <Route path="/my-account/orders/:id" element={<OrderDetails />} />
              <Route path="/my-account/invoices" element={<Invoices />} />
              <Route
                path="/my-account/invoices/:id"
                element={<InvoiceDetails />}
              />
              <Route path="/store/accounts" element={<Accounts />} />
              <Route path="/store/logs" element={<Logs />} />
              <Route path="/store/crons" element={<Crons />} />
              <Route path="/store/categories" element={<Categories />} />
              <Route path="/exact/settings" element={<Settings />} />
            </>}
            <Route path="/customer-login" element={<Login />} />
            <Route path="/exact/store-login" element={<StoreLogin />} />
            <Route path="/try-now" element={<Register />} />
            <Route path="/reset-password/:hash" element={<ResetPassword />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
          <Snackbar
            open={snackbar.open}
            autoHideDuration={3000}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <Alert
              onClose={handleClose}
              severity={snackbar.severity}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Suspense>
      </ThemeProvider>
    </DataContext.Provider>
  );
}

export default App;
