import React from "react";
import {
  FormControl,
  InputLabel,
  Input,
  DialogTitle,
  IconButton,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "../../axios";
import { Close as CloseIcon } from "@mui/icons-material";
import countries from "../../countries";
import { useState } from "react";
import { useContext } from "react";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";

export default function AccountDialog({
  open,
  setOpen,
  handleChange,
  inputValue,
  refresh,
  onSave,
}) {
  const [isInApi, setIsInApi] = useState(false);
  const { setSnackbar } = useContext(DataContext);
  const { t } = useTranslation();

  const save = () => {
    setIsInApi(true);

    if (inputValue.entity_type === "account") {
      axios
        .post("/my-account/related-accounts", inputValue)
        .then((response) => {
          refresh().then(() => {
            onSave(response.data.data.ID, "account", inputValue.account_type);
            close();
          });
        })
        .catch(() => {
          setSnackbar({
            message: t("An error occured while saving."),
            severity: "error",
            open: true,
          });

          setIsInApi(false);
        });
    } else {
      axios
        .post("/my-account/related-contacts", {
          ...inputValue,
          account_id: inputValue.account_id,
        })
        .then((response) => {
          close();
          refresh().then(() => {
            onSave(response.data.data.ID, inputValue.entity_type);
            close();
          });
        })
        .catch(() => {
          setSnackbar({
            message: t("An error occured while saving."),
            severity: "error",
            open: true,
          });

          setIsInApi(false);
        });
    }
  };

  const close = () => {
    setOpen((prevState) => {
      return {
        ...prevState,
        account: false,
      };
    });

    setIsInApi(false);
  };

  const title = () => {
    if (inputValue.entity_type === "account") {
      if (inputValue.account_type === "delivery_account") {
        return "Delivery Account";
      } else if (inputValue.account_type === "invoice_account") {
        return "Invoice Account";
      }
    } else if (inputValue.entity_type === "deliver_to") {
      return "Deliver To Contact";
    } else if (inputValue.entity_type === "ordered_by") {
      return "Ordered By Contact";
    } else if (inputValue.entity_type === "invoice_to") {
      return "Invoice To Contact";
    }

    return "Contact";
  };

  return (
    <Dialog sx={{ m: 0, p: 2 }} open={open} onClose={close}>
      <DialogTitle>
        {inputValue.entity_id == null ? "New" : "Edit"}{" "}
        <span style={{ display: "none" }}>{inputValue.entity_type}</span>
        {title()}
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ marginTop: 1 }}>
          {inputValue.entity_type === "account" ? (
            <>
              <FormControl sx={{ width: "100%", marginBottom: 1 }}>
                <InputLabel htmlFor="name">{t("Name")}</InputLabel>
                <Input
                  id="name"
                  onChange={handleChange}
                  value={inputValue.name}
                />
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: 1 }}>
                <InputLabel htmlFor="email">{t("E-mail")}</InputLabel>
                <Input
                  id="email"
                  onChange={handleChange}
                  value={inputValue.email}
                />
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: 1 }}>
                <InputLabel htmlFor="address_line_1">{t("Address Line 1")}</InputLabel>
                <Input
                  id="address_line_1"
                  onChange={handleChange}
                  value={inputValue.address_line_1}
                />
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: 1 }}>
                <InputLabel htmlFor="address_line_2">{t("Address Line 2")}</InputLabel>
                <Input
                  id="address_line_2"
                  onChange={handleChange}
                  value={inputValue.address_line_2}
                />
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: 1 }}>
                <InputLabel htmlFor="address_line_3">{t("Address Line 3")}</InputLabel>
                <Input
                  id="address_line_3"
                  onChange={handleChange}
                  value={inputValue.address_line_3}
                />
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: 1 }}>
                <InputLabel htmlFor="address_phone">{t("Phone")}</InputLabel>
                <Input
                  id="address_phone"
                  onChange={handleChange}
                  value={inputValue.address_phone}
                />
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: 1 }}>
                <InputLabel htmlFor="address_postcode">{t("Postcode")}</InputLabel>
                <Input
                  id="address_postcode"
                  onChange={handleChange}
                  value={inputValue.address_postcode}
                />
              </FormControl>
              <FormControl
                sx={{ width: "100%", marginBottom: 1 }}
                variant="standard"
              >
                <InputLabel id="country-label">{t("Country")}</InputLabel>
                <Select
                  labelId="country-label"
                  label={t("Country")}
                  value={inputValue.address_country}
                  onChange={handleChange}
                >
                  {countries.map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: 1 }}>
                <InputLabel htmlFor="address_city">{t("City")}</InputLabel>
                <Input
                  id="address_city"
                  onChange={handleChange}
                  value={inputValue.address_city}
                />
              </FormControl>
            </>
          ) : (
            <>
              <FormControl sx={{ width: "100%", marginBottom: 1 }} required>
                <InputLabel htmlFor="first_name">{t("First Name")}</InputLabel>
                <Input
                  id="first_name"
                  onChange={handleChange}
                  value={inputValue.first_name}
                />
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: 1 }} required>
                <InputLabel htmlFor="last_name">{t("Last Name")}</InputLabel>
                <Input
                  id="last_name"
                  onChange={handleChange}
                  value={inputValue.last_name}
                />
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: 1 }} required>
                <InputLabel htmlFor="e_mail">E-Mail</InputLabel>
                <Input
                  id="e_mail"
                  onChange={handleChange}
                  value={inputValue.e_mail}
                />
              </FormControl>
              {/* <FormControl sx={{ width: "100%", marginBottom: 1 }} required>
                <InputLabel htmlFor="mobile_phone">Mobile Phone</InputLabel>
                <Input
                  id="mobile_phone"
                  onChange={handleChange}
                  value={inputValue.mobile_phone}
                />
              </FormControl> */}
              <FormControl sx={{ width: "100%", marginBottom: 1 }} required>
                <InputLabel htmlFor="phone">Phone</InputLabel>
                <Input
                  id="phone"
                  onChange={handleChange}
                  value={inputValue.phone}
                />
              </FormControl>
            </>
          )}
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
            <Button variant="contained" onClick={save} disabled={isInApi}>
              {t("Save")}
            </Button>
          </Box>
          <IconButton
            aria-label="close"
            onClick={close}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
