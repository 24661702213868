import { Box, CircularProgress } from "@mui/material";
import React from "react";

export default function MiniLoading() {
  return (
    <Box display={"flex"} justifyContent={"center"} width={"100%"}>
      <CircularProgress color="apimasters" />
    </Box>
  );
}
