import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { numberFormatter } from "../../formatter";
import { TableFooter } from "@mui/material";
import styles from "./Styles.module.scss";
import { useTranslation } from "react-i18next";

export default function LinesTable(props) {
  const total = () => {
    return subTotal() + vat();
  };

  const subTotal = () => {
    let total = 0;

    props.data.forEach((element) => {
      total += parseFloat(element.UnitPrice) * parseFloat(element.Quantity);
    });

    return Math.abs(total);
  };

  const discount = () => {
    let discount = 0;

    props.data.forEach((element) => {
      discount +=
        parseFloat(element.Discount) *
        parseFloat(element.UnitPrice) *
        parseFloat(element.Quantity);
    });

    return Math.abs(discount);
  };

  const vat = () => {
    let vat = 0;

    props.data.forEach((element) => {
      vat +=
        parseFloat(element.VATPercentage) *
        parseFloat(element.NetPrice) *
        parseFloat(element.Quantity);
    });

    return Math.abs(vat);
  };

  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <span className={styles.tableThead}>{t("SKU")}</span>
            </TableCell>
            <TableCell>
              <span className={styles.tableThead}>{t("Description")}</span>
            </TableCell>
            <TableCell>
              <span className={styles.tableThead}>{t("Unit Price")}</span>
            </TableCell>
            <TableCell>
              <span className={styles.tableThead}>{t("Quantity")}</span>
            </TableCell>
            <TableCell>
              <span className={styles.tableThead}>{t("Discount")}</span>
            </TableCell>
            <TableCell>
              <span className={styles.tableThead}>{t("Amount excl. VAT")}</span>
            </TableCell>
            <TableCell>
              <span className={styles.tableThead}>{t("VAT %")}</span>
            </TableCell>
            <TableCell>
              <span className={styles.tableThead}>{t("Total")}</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => (
            <TableRow
              key={row.ID}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                {row.ItemCode}
              </TableCell>
              <TableCell>{row.Description}</TableCell>
              <TableCell>
                {numberFormatter.format(Math.abs(row.UnitPrice))}
              </TableCell>
              <TableCell>{row.Quantity}</TableCell>
              <TableCell>
                {numberFormatter.format(
                  Math.abs(
                    parseFloat(row.Discount) *
                    parseFloat(row.UnitPrice * row.Quantity)
                  )
                )}
              </TableCell>
              <TableCell>
                {numberFormatter.format(Math.abs(row.AmountDC))}
              </TableCell>
              <TableCell>
                {row.VATPercentage ? row.VATPercentage * 100 : "0"}%
              </TableCell>
              <TableCell>
                {numberFormatter.format(
                  Math.abs(row.AmountDC) +
                  Math.abs(
                    typeof row.VATAmountDC == "undefined"
                      ? row.VATAmount
                      : row.VATAmountDC
                  )
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={3}></TableCell>
            <TableCell colSpan={6}>
              <span
                style={{ fontSize: 16, textAlign: "right", display: "block" }}
              >
                {t("Sub Total")}: {numberFormatter.format(subTotal())}
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3}></TableCell>
            <TableCell colSpan={6}>
              <span
                style={{ fontSize: 16, textAlign: "right", display: "block" }}
              >
                {t("BTW")}: {numberFormatter.format(vat())}
              </span>
            </TableCell>
          </TableRow>
          {discount() > 0 && (
            <TableRow>
              <TableCell colSpan={3}></TableCell>
              <TableCell colSpan={6}>
                <span
                  style={{ fontSize: 16, textAlign: "right", display: "block" }}
                >
                  {t("Discount")}: {numberFormatter.format(discount())}
                </span>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={3}></TableCell>
            <TableCell colSpan={6}>
              <span
                style={{ fontSize: 16, textAlign: "right", display: "block" }}
              >
                {t("Total")}: {numberFormatter.format(total() - discount())}
              </span>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
