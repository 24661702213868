import axios from "../axios";

const findInCart = (cart, id) => {
  return cart.find((cartItem) => cartItem.id === id);
};

const getUserCart = async () => {
  return await new Promise(function (resolve, reject) {
    axios
      .get("/cart")
      .then((response) => {
        resolve(Object.values(response.data.cart));
      })
      .catch(reject);
  });
};

const calculateSpecialPrice = (product) => {
  let price = 0;
  let minimumPrice = Number.MAX_SAFE_INTEGER;
  let tempPrice = null;

  if (product.sales_item_price != null) {
    price = parseFloat(product.sales_item_price?.Price) ?? 0;
  }

  if (product?.special_sales_item_price != null) {
    let tempPrice = parseFloat(product.special_sales_item_price.Price);

    if (tempPrice < minimumPrice) {
      minimumPrice = tempPrice;
    }
  } else {
    if (product?.sales_price_customer_item != null) {
      if (product.sales_price_customer_item.Discount === 0) {
        tempPrice = product.sales_price_customer_item.NewPrice;
      } else {
        tempPrice = price - price * product.sales_price_customer_item.Discount;
      }

      if (tempPrice < minimumPrice) {
        minimumPrice = tempPrice;
      }
    }

    if (product?.sales_price_customer_item_group != null) {
      if (product.sales_price_customer_item_group.Discount === 0) {
        tempPrice = product.sales_price_customer_item_group.NewPrice;
      } else {
        tempPrice =
          price - price * product.sales_price_customer_item_group.Discount;
      }

      if (tempPrice < minimumPrice) {
        minimumPrice = tempPrice;
      }
    }

    if (product?.sales_price_standard_item != null) {
      if (product.sales_price_standard_item.Discount === 0) {
        tempPrice = product.sales_price_standard_item.NewPrice;
      } else {
        tempPrice = price - price * product.sales_price_standard_item.Discount;
      }

      if (tempPrice < minimumPrice) {
        minimumPrice = tempPrice;
      }
    }

    if (product?.sales_price_standard_item_group != null) {
      if (product.sales_price_standard_item_group.Discount === 0) {
        tempPrice = product.sales_price_standard_item_group.NewPrice;
      } else {
        tempPrice =
          price - price * product.sales_price_standard_item_group.Discount;
      }

      if (tempPrice < minimumPrice) {
        minimumPrice = tempPrice;
      }
    }
  }

  return minimumPrice === Number.MAX_SAFE_INTEGER ? null : minimumPrice;
};

export { findInCart, getUserCart, calculateSpecialPrice };
