import { AppBar, Container, Box, useTheme, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import React, { useContext } from "react";
import { DataContext } from "../../App";
import axios from "../../axios";
import Auth from "../../Auth";
import Button from "@mui/material/Button";
import moment from "moment/min/moment-with-locales";
import { useTranslation, Trans } from "react-i18next";


const TopNavBar = () => {
	const { auth } = useContext(DataContext);

	return (
		auth.currentUser &&
		(Auth.getUserType() === "store" ? (
			<StoreUserTopNavBar />
		) : (
			<NormalUserTopNavBar />
		))
	);
};

const StoreUserTopNavBar = () => {
	const { auth } = useContext(DataContext);
	const theme = useTheme();
	const { t } = useTranslation();
	const daysLeft = Auth.getUser()?.subscription_end_date == null ? 0 : moment(Auth.getUser()?.subscription_end_date).diff(moment(), "days");
	const hoursLeft = Auth.getUser()?.subscription_end_date == null ? 0 : moment(Auth.getUser()?.subscription_end_date).diff(moment(), "hours");

	return (
		auth.currentUser && (
			<AppBar position="relative" component="nav" sx={{ background: "#fff" }}>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					// py={"8.5px"}
					sx={{
						background: "var(--primary)",
						width: "100%",
						paddingBottom: 0,
						paddingTop: 0,
					}}
				>
					<Container>

						<div />
						{auth.currentUser ? (
							<>
								<Box sx={{ display: "flex" }}>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											marginRight: 1,
											fontSize: "0.875rem",
										}}
									>
										<span>{window.storeSettings.site_name}</span>
									</Box>
									<Box sx={{ flexGrow: 1 }} />
									{
										Auth.getUser()?.store_division != null &&
										<>
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													marginRight: 1,
													fontSize: "0.875rem",
												}}
											>
												<span>
													{daysLeft === 0 ?
														<Trans
															i18nKey="subscription_left_hours"
															daysLeft={hoursLeft}>
															Subscription ends/renews after <b>{{ hoursLeft }}</b> hours.</Trans>
														:
														<Trans
															i18nKey="subscription_left"
															daysLeft={daysLeft}>
															Subscription ends/renews after <b>{{ daysLeft }}</b> hours.</Trans>
													}


												</span>
											</Box>
											<Box
												sx={{
													marginRight: 1,
													borderRight: "1px solid white",
													marginTop: 1,
													marginBottom: 1,
												}}
											>
												&nbsp;
											</Box>
										</>
									}
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											marginRight: 1,
											fontSize: "0.875rem",
										}}
									>
										<span>
											<b>
												{Auth.getUser().first_name ?? "-"}{" "}
												{Auth.getUser().last_name ?? "-"}
											</b>
										</span>
									</Box>
									<Box
										sx={{
											marginRight: 1,
											borderRight: "1px solid white",
											marginTop: 1,
											marginBottom: 1,
										}}
									>
										&nbsp;
									</Box>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											marginRight: 1,
											fontSize: "0.875rem",
										}}
									>
										<span>
											{t("Division")}:{" "}
											<b>
												{Auth.getUser().store_division ?? '-'}
											</b>
										</span>
									</Box>
									<Box
										sx={{
											marginRight: 1,
											borderRight: "1px solid white",
											marginTop: 1,
											marginBottom: 1,
										}}
									>
										&nbsp;
									</Box>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											marginRight: 1,
											fontSize: "0.875rem",
										}}
									>
										<span><b>{Auth.getUser().email ?? '-'} </b>
										</span>
									</Box>
									<Box
										sx={{
											marginLeft: 1,
											marginRight: 1,
											borderRight: "1px solid white",
											marginTop: 1,
											marginBottom: 1,
										}}
									>
										&nbsp;
									</Box>
									<Button
										onClick={() => {
											axios
												.post("/exact/store/logout")
												.then((response) => {
													Auth.deleteToken();
													window.location.href = "/";
												})
												.catch(console.log);
										}}
										sx={{
											fontWeight: "bold",
											color: theme.palette.getContrastText(window.storeSettings.primary_color)
										}}
										color="apimasters"
									>
										{t("Logout")}
									</Button>
								</Box>
							</>
						) : (
							<></>
						)}

					</Container>
				</Box>
			</AppBar >
		)
	);
};

export const LanguageSelector = () => {
	return <FormControl>
		<Select
			value={localStorage.getItem("lang") ?? window.storeSettings.language}
			label={""}
			color={"primary"}
			// sx={{ color: "white" }}
			// variant="standard"
			sx={{ marginLeft: 3 }}
			size="small"
			onChange={
				(e) => {
					localStorage.setItem('lang', e.target.value);
					window.location.reload();
				}}>
			<MenuItem value={'en'}>English</MenuItem>
			<MenuItem value={'nl'}>Dutch</MenuItem>
			<MenuItem value={'de'}>Deutsch</MenuItem>
		</Select>
	</FormControl>;
}

const NormalUserTopNavBar = () => {
	const { auth } = useContext(DataContext);
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		auth.currentUser && (
			<AppBar position="relative" component="nav" sx={{ background: "#fff" }}>
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					// py={"8.5px"}
					sx={{
						background: "var(--primary)",
						width: "100%",
						paddingBottom: 0,
						paddingTop: 0,
					}}
				>
					<Container>
						<div />
						{auth.currentUser ? (
							<>
								<Box sx={{ display: "flex" }}>
									<Box sx={{ flexGrow: 1 }} />
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											marginLeft: 1,
											fontSize: "0.875rem",
										}}
									>
										<span>
											{t("Account Code")}: <b>{Auth.getUser().Code ?? "-"}</b>
										</span>
									</Box>
									<Box
										sx={{
											marginLeft: 1,
											marginRight: 1,
											borderRight: "1px solid white",
											marginTop: 1,
											marginBottom: 1,
										}}
									>
										&nbsp;
									</Box>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											marginRight: 1,
											fontSize: "0.875rem",
										}}
									>
										<span>
											{t("Account Name")}: <b>{Auth.getUser().Name ?? "-"}</b>
										</span>
									</Box>
									<Box
										sx={{
											marginLeft: 1,
											marginRight: 1,
											borderRight: "1px solid white",
											marginTop: 1,
											marginBottom: 1,
										}}
									>
										&nbsp;
									</Box>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											marginRight: 1,
											fontSize: "0.875rem",
										}}
									>
										<span>
											{t("Account E-mail")}: <b>{Auth.getUser().Email ?? "-"} </b>
										</span>
									</Box>
									<Box
										sx={{
											marginLeft: 1,
											marginRight: 1,
											borderRight: "1px solid white",
											marginTop: 1,
											marginBottom: 1,
										}}
									>
										&nbsp;
									</Box>
									<Button
										onClick={() => {
											axios
												.post("/my-account/logout")
												.then((response) => {
													Auth.deleteToken();
													window.location.href = "/";
												})
												.catch(console.log);
										}}
										sx={{
											fontWeight: "bold",
											color: theme.palette.getContrastText(window.storeSettings.primary_color)
										}}
									// color="apimasters"
									>
										{t("Logout")}
									</Button>
								</Box>
							</>
						) : (
							<></>
						)}
					</Container>
				</Box>
			</AppBar>
		)
	);
};

export default TopNavBar;
