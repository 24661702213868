import React from "react";
import {
  Grid,
  FormControl,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Contacts({
  contacts,
  inputValue,
  handleChange,
  handleOpenContactDialog,
}) {
  const formatContact = (contact) => {
    let _contact = contact.FullName;

    return _contact;
  };

  const { t } = useTranslation();

  return (
    <Grid
      container
      item
      spacing={1}
      sx={{ paddingLeft: "0px !important", paddingRight: "0px !important" }}
    >
      <Grid item xs={4}>
        <FormControl fullWidth spacing={1}>
          <FormLabel>{t("Ordered By Contact")}</FormLabel>
          <FormControl fullWidth sx={{ marginTop: 1 }}>
            <InputLabel id="ordered-by-contact-label">{t("Contact")}</InputLabel>
            <Select
              labelId="ordered-by-contact-label"
              label={t("Select")}
              size="sm"
              fullWidth
              onChange={(e) => {
                handleChange(
                  {
                    target: {
                      id: "ordered_by_contact",
                      value: e.target.value,
                    },
                  },
                  "main"
                );
              }}
              disabled={inputValue.main.ordered_by_account === ""}
              value={inputValue.main.ordered_by_contact ?? ''}
            >
              {contacts.map((contact) => {
                return (
                  <MenuItem value={contact.ID} key={contact.ID}>
                    {formatContact(contact)}{" "}
                    {contact.Account === inputValue.main.ordered_by_account
                      ? t("(Linked to selected account)")
                      : ""}
                    {/* &nbsp;
                    <Link
                      onClick={(e) => {
                        handleOpenContactDialog(e, "ordered_by");
                      }}
                      id={contact.ID}
                      data-row={JSON.stringify(contact)}
                    >
                      Edit
                    </Link> */}
                  </MenuItem>
                );
              })}
              <MenuItem
                onClick={(e) => {
                  handleOpenContactDialog(e, "ordered_by");
                }}
              >
                {t("Add new")}
              </MenuItem>
            </Select>
          </FormControl>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <FormLabel>{t("Delivery To Contact")}</FormLabel>
          <FormControl fullWidth sx={{ marginTop: 1 }}>
            <InputLabel id="delivery-to-contact-label">{t("Contact")}</InputLabel>
            <Select
              labelId="delivery-to-contact-label"
              label={t("Select")}
              size="sm"
              fullWidth
              onChange={(e) => {
                handleChange(
                  {
                    target: {
                      id: "deliver_to_contact",
                      value: e.target.value,
                    },
                  },
                  "main"
                );
              }}
              value={inputValue.main.deliver_to_contact ?? ''}
              disabled={inputValue.main.deliver_to_account === ""}
            >
              {contacts.map((contact) => {
                return (
                  <MenuItem value={contact.ID} key={contact.ID}>
                    {formatContact(contact)}{" "}
                    {contact.Account === inputValue.main.deliver_to_account
                      ? t("(Linked to selected account)")
                      : ""}
                    {/* &nbsp;
                    <Link
                      onClick={(e) => {
                        handleOpenContactDialog(e, "deliver_to");
                      }}
                      id={contact.ID}
                      data-row={JSON.stringify(contact)}
                    >
                      Edit
                    </Link> */}
                  </MenuItem>
                );
              })}
              <MenuItem
                onClick={(e) => {
                  handleOpenContactDialog(e, "deliver_to");
                }}
              >
                {t("Add new")}
              </MenuItem>
            </Select>
          </FormControl>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <FormLabel>{t("Invoice To Contact")}</FormLabel>
          <FormControl fullWidth sx={{ marginTop: 1 }}>
            <InputLabel id="invoice-to-contact-label">{t("Contact")}</InputLabel>
            <Select
              labelId="invoice-to-contact-label"
              label={t("Select")}
              size="sm"
              fullWidth
              onChange={(e) => {
                handleChange(
                  {
                    target: {
                      id: "invoice_to_contact",
                      value: e.target.value,
                    },
                  },
                  "main"
                );
              }}
              value={inputValue.main.invoice_to_contact ?? ''}
              disabled={inputValue.main.invoice_to_account === ""}
            >
              {contacts.map((contact) => {
                return (
                  <MenuItem
                    value={contact.ID}
                    key={contact.ID}
                    data-row={JSON.stringify(contact)}
                  >
                    {formatContact(contact)}{" "}
                    {contact.Account === inputValue.main.invoice_to_account
                      ? t("(Linked to selected account)")
                      : ""}
                    {/* &nbsp;
                    <Link
                      onClick={(e) => {
                        handleOpenContactDialog(e, "invoice_to");
                      }}
                      id={contact.ID}
                      data-row={JSON.stringify(contact)}
                    >
                      Edit
                    </Link> */}
                  </MenuItem>
                );
              })}
              <MenuItem
                onClick={(e) => {
                  handleOpenContactDialog(e, "invoice_to");
                }}
              >
                {t("Add new")}
              </MenuItem>
            </Select>
          </FormControl>
        </FormControl>
      </Grid>
    </Grid>
  );
}
