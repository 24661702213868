/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import styles from "./Styles.module.scss";
import {
    Breadcrumbs,
    Container,
    Grid,
    Link as BCLink,
    Card,
    CardContent,
    Typography,
    FormControl,
    TextField,
    Button,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import axios from "../../axios";
import Auth from "../../Auth";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";

const MyAccount = () => {
    const { setSnackbar } = useContext(DataContext);
    const [saveButtonDisabled, setSaveButtonDisabled] = React.useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState({
        password: "",
        password_again: "",
    });

    const handleChange = (event) => {
        setInputValue((prevState) => ({
            ...prevState,
            [event.target.id]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value,
        }));
    };

    const saveSettings = () => {
        setSaveButtonDisabled(true);
        axios
            .put("/my-account", inputValue)
            .then((response) => {
                setSnackbar({
                    message:
                        t("User details succesfully updated."),
                    severity: "success",
                    open: true,
                });
            })
            .catch(({ response }) => {
                setSnackbar({
                    message:
                        t(response.data?.message ||
                            "An error occured while saving user details."),
                    severity: "error",
                    open: true,
                });
            }).finally(() => {
                setSaveButtonDisabled(false);
            });
    };

    const handleClick = (event) => {
        event.preventDefault();
        navigate(event.target.pathname);
    };

    return (
        <div>
            <Container sx={{ pb: "1rem" }}>
                <div role="presentation" onClick={handleClick}>
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        separator={<NavigateNext fontSize="small" />}
                        sx={{ p: "14px 0" }}
                    >
                        <BCLink
                            className={styles.brdHov}
                            underline="none"
                            color="inherit"
                            href="/"
                            fontSize={"14px"}
                        >
                            {t("Home")}
                        </BCLink>
                        <BCLink
                            underline="none"
                            color="text.primary"
                            href="/my-account/most-purchased-products"
                            aria-current="page"
                            fontSize={"14px"}
                        >
                            {t("My Account")}
                        </BCLink>
                    </Breadcrumbs>
                </div>
                <Grid
                    container
                    item
                    xs={12}
                    spacing={1}
                    alignItems="center"
                    justify="center"
                    sx={{ marginTop: 2 }}
                >
                    <Container>
                        <Grid
                            container
                            spacing={3}
                            justifyContent="center"
                            alignItems="stretch"
                        >
                            <Grid item xs={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography level="title-md">
                                            {t("E-mail")}
                                        </Typography>
                                        <FormControl
                                            sx={{ width: "100%", marginTop: 1 }}
                                        >
                                            <TextField
                                                id="e_mail"
                                                name="e_mail"
                                                value={Auth.getUser().Email}
                                                disabled
                                            />
                                        </FormControl>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography level="title-md">
                                            {t("Account Code")}
                                        </Typography>
                                        <FormControl
                                            sx={{ width: "100%", marginTop: 1 }}
                                        >
                                            <TextField
                                                id="code"
                                                name="code"
                                                value={Auth.getUser().Code.trim()}
                                                disabled
                                            />
                                        </FormControl>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography level="title-md">
                                            {t("Password")}
                                        </Typography>
                                        <FormControl
                                            sx={{ width: "100%", marginTop: 1 }}
                                        >
                                            <TextField
                                                id="password"
                                                type="password"
                                                name="password"
                                                value={inputValue.password}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography level="title-md">
                                            {t("Password Again")}
                                        </Typography>
                                        <FormControl
                                            sx={{ width: "100%", marginTop: 1 }}
                                        >
                                            <TextField
                                                id="password_again"
                                                name="password_again"
                                                type="password"
                                                value={inputValue.password_again}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>

                    <Button
                        variant="contained"
                        sx={{ marginTop: 3, marginLeft: 3 }}
                        size="small"
                        onClick={saveSettings}
                        disabled={saveButtonDisabled}
                        color="apimasters"
                    >
                        {t("Save")}
                    </Button>
                </Grid>
            </Container>
        </div>
    );
};

export default React.memo(MyAccount);
