/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Breadcrumbs, Container, Grid, Link as BCLink } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import axios from "../../axios";
import { default as InvoiceDetailsContainer } from "../../components/MyAccount/InvoiceDetails";
import MiniLoading from "../../components/MiniLoading";
import styles from "./Styles.module.scss";
import { useTranslation } from "react-i18next";

export default function InvoiceDetails() {
    const urlParams = useParams();
    const { t } = useTranslation();
    const id = urlParams.id;

    const [invoice, setInvoice] = useState(null);

    useEffect(() => {
        fetchInvoice().then(function (res) {
            setInvoice(res);
        });
    }, []);

    const fetchInvoice = async () => {
        return await new Promise(function (resolve, reject) {
            axios
                .get("/my-account/invoices/" + id)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch(reject);
        });
    };

    return (
        <div>
            <Container sx={{ pb: "1rem" }}>
                <div role="presentation">
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        separator={<NavigateNext fontSize="small" />}
                        sx={{ p: "14px 0" }}
                    >
                        <BCLink
                            className={styles.brdHov}
                            underline="none"
                            color="inherit"
                            href="/"
                            fontSize={"14px"}
                        >
                            {t("Home")}
                        </BCLink>
                        <BCLink
                            className={styles.brdHov}
                            underline="none"
                            color="inherit"
                            href="/my-account"
                            fontSize={"14px"}
                        >
                            {t("My Account")}
                        </BCLink>
                        <BCLink
                            className={styles.brdHov}
                            underline="none"
                            color="inherit"
                            href="/my-account/invoices"
                            fontSize={"14px"}
                        >
                            {t("Invoices")}
                        </BCLink>
                        <BCLink
                            underline="none"
                            color="text.primary"
                            href={"/my-account/invoices/" + id}
                            aria-current="page"
                            fontSize={"14px"}
                        >
                            {t("Invoice")} #{invoice?.InvoiceNumber ?? "-"}
                        </BCLink>
                    </Breadcrumbs>
                </div>
                <Grid container item spacing={2} sx={{ justifyContent: "center" }}>
                    <Grid container item>
                        {invoice == null ? (
                            <MiniLoading />
                        ) : (
                            <InvoiceDetailsContainer data={invoice} />
                        )}
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
