const AUTH_KEY = "token";
const USER_KEY = "user";
const THEME_KEY = "currentStoreTheme";

class Auth {
  deleteToken() {
    localStorage.removeItem(AUTH_KEY);
    localStorage.removeItem(THEME_KEY);

    this.deleteUser();
  }

  deleteUser() {
    localStorage.removeItem(USER_KEY);
  }

  setToken(token) {
    localStorage.setItem(AUTH_KEY, token);
  }

  setUser(user) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  getToken() {
    return localStorage.getItem(AUTH_KEY);
  }

  getUser() {
    return JSON.parse(localStorage.getItem(USER_KEY));
  }

  getUserType() {
    return JSON.parse(localStorage.getItem(USER_KEY))?.is_store_user
      ? "store"
      : "normal";
  }

  isAuthenticated() {
    return localStorage.getItem(AUTH_KEY) != null;
  }
}

const instance = new Auth();

export default instance;
