export const validateSignUp = (data, t) => {
  const errors = {};

  if (!data.email) {
    errors.email = t("Email Required");
  } else if (!/\S+@\S+\.\S+/.test(data.email)) {
    errors.email = t("Email Address Is Invalid");
  } else {
    delete errors.email;
  }

  if (!data.userName) {
    errors.userName = t("Username Required.");
  } else if (data.userName.length < 3) {
    errors.userName = t("You Must Enter Valid Username.");
  } else {
    delete errors.userName;
  }

  if (!data.password) {
    errors.password = t("Password Required.");
  } else if (data.password.length < 6) {
    errors.password = t("Enter Stronger Password.");
  } else {
    delete errors.password;
  }

  if (!data.repeatPassword) {
    errors.repeatPassword = t("This Field Is Required.");
  } else if (data.repeatPassword !== data.password) {
    errors.repeatPassword = t("Passwords Must Match.");
  } else {
    delete errors.repeatPassword;
  }

  if (!data.isAccepted) {
    errors.isAccepted = t("You Must Accept!.");
  } else {
    delete errors.isAccepted;
  }

  return errors;
};

export const validateForgotPassword = (data, t) => {
  const errors = {};

  if (!data.code) {
    errors.code = t("Account Code Required");
  } else if (!Number.isInteger(parseInt(data.code))) {
    errors.code = t("Account Code Is Invalid, must be numeric value");
  } else {
    delete errors.code;
  }

  return errors;
};

export const validateResetPassword = (data, t) => {
  const errors = {};

  if (!data.password) {
    errors.password = t("Password Required.");
  } else {
    delete errors.password;
  }

  if (!data.passwordAgain) {
    errors.passwordAgain = t("Password Again Required.");
  } else {
    delete errors.passwordAgain;
  }

  return errors;
};

export const validateSignIn = (data, t) => {
  const errors = {};

  if (!data.code) {
    errors.code = t("Account Code Required");
  } else if (!Number.isInteger(parseInt(data.code))) {
    errors.code = t("Account Code Is Invalid, must be numeric value");
  } else {
    delete errors.code;
  }

  if (!data.password) {
    errors.password = t("Password Required.");
  } else {
    delete errors.password;
  }

  return errors;
};

export const handleFirebaseError = (error) => {
  switch (error) {
    case "auth/email-already-in-use":
      return {
        message: "e-mail is already linked to an account or is invalid",
        severity: "error",
      };
    case "auth/network-request-failed":
      return { message: "check Your Connection!.", severity: "error" };
    case "auth/user-not-found":
      return { message: "user not found!", severity: "error" };
    case "auth/wrong-password":
      return { message: "your password is wrong", severity: "error" };
    case "auth/too-many-requests":
      return { message: "to many request, try later.", severity: "error" };
    case "regd":
      return { message: "thanks for register.", severity: "success" };
    case "lgsc":
    case "golg":
      return { message: "welcome back...", severity: "success" };
    case "okcheckout":
      return {
        message:
          "Thank you for purchase. Please remember your purchase process takes 2-3 days",
        severity: "success",
      };
    default:
      return {
        message: "Something bad happened! try again later...",
        severity: "error",
      };
  }
};
