import React, { useContext, useState } from "react";
import { DataContext } from "../../App";
import {
  Breadcrumbs,
  Container,
  Grid,
  Link as BCLink,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { NavigateNext, Close } from "@mui/icons-material";
import { TbRefresh } from "react-icons/tb";
import styles from "./Styles.module.scss";
import "./Style.css";
import axios from "../../axios";
import AddressSelection from "../../components/Cart/AddressSelection";
import { useEffect } from "react";
import Quantity from "../../components/Quantity";
import { numberFormatter } from "../../formatter";
import Auth from "../../Auth";
import { Bars } from "react-loader-spinner";
import { calculateSpecialPrice, getUserCart } from "../../components/Cart";
import { useTranslation } from "react-i18next";

/* eslint-disable react-hooks/exhaustive-deps */

const Cart = () => {
  const { cartList, setCartList, setSnackbar } = useContext(DataContext);

  const navigate = useNavigate();
  const [addresses, setAddresses] = useState({
    invoice: [],
    delivery: [],
    ordered: [],
  });
  const [contacts, setContacts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [isInApi, setIsInApi] = useState(false);

  const initialInputState = {
    main: {
      ordered_by_contact: Auth.getUser().MainContact,
      deliver_to_contact: Auth.getUser().MainContact,
      invoice_to_contact: Auth.getUser().MainContact,

      ordered_by_account: Auth.getUser().ID,
      deliver_to_account: Auth.getUser().ID,
      invoice_to_account: Auth.getUser().ID,

      ordered_by_address: "",
      delivery_address: "",
      invoice_to_address: "",
    },
    account: {
      name: null,
      entity_type: null,
      entity_id: null,
      email: "",
      address_line_1: "",
      address_line_2: "",
      address_line_3: "",
      address_phone: "",
      address_postcode: "",
      address_country: "NL",
      address_city: "",
      address_type: "",
    },
    address: {
      address_id: null,
      address_line_1: "",
      address_line_2: "",
      address_line_3: "",
      address_phone: "",
      address_postcode: "",
      address_country: "NL",
      address_city: "",
      address_type: "",
    },
  };
  const [inputValue, setInputValue] = React.useState(initialInputState);

  useEffect(() => {
    refreshAddresses();
    refreshAccounts();

    fetchContacts().then(function (res) {
      setContacts(res);
    });
  }, []);

  useEffect(() => {
    refreshAddresses();
  }, [inputValue.main.deliver_to_account, inputValue.main.invoice_to_account]);

  const refreshAddresses = () => {
    return new Promise((resolve, reject) => {
      setAddresses({
        invoice: [],
        delivery: [],
        ordered: [],
      });
      fetchAddresses()
        .then(function (res) {
          setInputValue((prevState) => {
            let delivery = res.delivery.find((row) => {
              return row.Main === 1;
            });

            let invoice = res.invoice.find((row) => {
              return row.Main === 1;
            });

            let ordered = res.ordered.find((row) => {
              return row.Main === 1;
            });

            return {
              ...prevState,
              main: {
                ...prevState.main,
                ordered_by_address:
                  ordered?.ID ?? prevState.main.ordered_by_address,
                delivery_address:
                  delivery?.ID ?? prevState.main.delivery_address,
                invoice_to_address:
                  invoice?.ID ?? prevState.main.invoice_to_address,
                ordered_by_contact:
                  ordered?.Contact ?? prevState.main.ordered_by_contact,
                deliver_to_contact:
                  delivery?.Contact ?? prevState.main.deliver_to_contact,
                invoice_to_contact:
                  invoice?.Contact ?? prevState.main.invoice_to_contact,
              },
            };
          });

          setAddresses(res);
          resolve(res);
        })
        .catch(reject);
    });
  };

  const refreshAccounts = () => {
    fetchAccounts().then(function (res) {
      setAccounts(res);
    });
  };

  const refreshAccountsAndContacts = () => {
    return new Promise((resolve, reject) => {
      fetchAccounts()
        .then(function (res) {
          setAccounts(res);

          fetchContacts()
            .then(function (res) {
              setContacts(res);

              resolve(true);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  };

  const fetchContacts = async () => {
    return await new Promise(function (resolve, reject) {
      axios
        .get("/my-account/contacts")
        .then((response) => {
          resolve(response.data.data);
        })
        .catch(reject);
    });
  };

  const fetchAddresses = async () => {
    return await new Promise(function (resolve, reject) {
      axios
        .get(
          "/my-account/addresses?invoice_account=" +
          inputValue.main.invoice_to_account +
          "&delivery_account=" +
          inputValue.main.deliver_to_account
        )
        .then((response) => {
          resolve(response.data.data);
        })
        .catch(reject);
    });
  };

  const fetchAccounts = async () => {
    return await new Promise(function (resolve, reject) {
      axios
        .get("/my-account/related-accounts")
        .then((response) => {
          resolve(response.data.data);
        })
        .catch(reject);
    });
  };

  const handleClick = (event) => {
    event.preventDefault();
    navigate(event.target.pathname);
  };

  const deleteFromCart = (event) => {
    let array = cartList;
    const productIndex = array.findIndex(
      (item) => item.id === event.currentTarget.id
    );
    array.splice(productIndex, 1);
    setCartList((prevState) => [...array]);

    axios.delete("/cart/" + event.currentTarget.id).then((response) => { });
  };

  const formatToCurrency = (amount) => {
    const onlyNumbers = Number(String(amount).replace(/[^0-9.-]+/g, ""));
    return onlyNumbers.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const subTotal = (status) => {
    let total = 0;

    cartList.forEach((_, idx) => {
      total += Number(
        parseFloat(getPrice(cartList[idx], "general", true)) *
        cartList[idx].quantity
      );
    });

    return status === "formatted" ? formatToCurrency(total) : total;
  };

  const total = () => {
    let total = 0;

    cartList.forEach((_, idx) => {
      if (cartList[idx].associatedModel.special_sales_item_price != null) {
        total +=
          parseFloat(getPrice(cartList[idx], "special", true)) *
          cartList[idx].quantity;
      } else {
        total +=
          parseFloat(getPrice(cartList[idx], "general", true)) *
          cartList[idx].quantity;
      }
    });

    return total;
  };

  const discount = () => {
    let discount = 0;

    cartList.forEach((_, idx) => {
      if (
        cartList[idx].associatedModel.special_sales_item_price?.Price != null
      ) {
        discount += parseFloat(
          (cartList[idx].associatedModel.sales_item_price.Price -
            cartList[idx].associatedModel.special_sales_item_price?.Price) *
          cartList[idx].quantity
        );
      }
    });

    return discount;
  };

  const vat = () => {
    let vat = 0;
    let percentage = 0;
    let price = 0;

    cartList.forEach((_, idx) => {
      if (cartList[idx].associatedModel.v_a_t_code != null) {
        percentage = cartList[idx].associatedModel.v_a_t_code.Percentage;

        if (cartList[idx].associatedModel.v_a_t_code != null) {
          if (
            cartList[idx].associatedModel.special_sales_item_price?.Price !=
            null
          ) {
            price =
              parseFloat(
                cartList[idx].associatedModel.special_sales_item_price?.Price
              ) * cartList[idx].quantity;
          } else {
            price =
              parseFloat(
                cartList[idx].associatedModel.sales_item_price?.Price
              ) * cartList[idx].quantity;
          }

          vat += price * percentage;
        }
      }
    });

    return vat;
  };

  const checkOut = (event) => {
    if (
      inputValue.main.delivery_address === "" ||
      inputValue.main.invoice_to_address === "" ||
      inputValue.main.ordered_by_address === ""
    ) {
      setSnackbar({
        message: t("All address fields must be filled."),
        severity: "error",
        open: true,
      });

      return;
    }

    setIsInApi(true);

    axios
      .post("/cart/proceed", { ...inputValue })
      .then((response) => {
        setSnackbar({
          message: t("Order successfully created."),
          severity: "success",
          open: true,
        });

        setCartList([]);

        navigate("/cart/thanks-for-order/" + response.data.order_number);
      })
      .catch(({ response }) => {
        setSnackbar({
          message:
            response.data?.message || t("An error occured while creating order."),
          severity: "error",
          open: true,
        });

        setIsInApi(false);

        if (typeof response.data?.cart_updated != "undefined") {
          getUserCart().then(function (cart) {
            setCartList([...cart]);
          });
        }

        if (response.data?.order_number != null) {
          setCartList([]);

          navigate("/cart/thanks-for-order/" + response.data.order_number);
        }
      });
  };

  const getPrice = (product, type = "general", excludeVat = false) => {
    let price = 0;

    if (type === "general") {
      price = parseFloat(product.associatedModel.sales_item_price.Price);
    } else {
      price = parseFloat(calculateSpecialPrice(product.associatedModel));
    }

    if (excludeVat && product.associatedModel.v_a_t_code != null) {
      let percentage = parseFloat(
        product.associatedModel.v_a_t_code.Percentage
      );

      if (product.associatedModel.v_a_t_code != null) {
        if (
          calculateSpecialPrice(product.associatedModel) != null &&
          type !== "general"
        ) {
          price = parseFloat(calculateSpecialPrice(product.associatedModel));
        } else {
          price = parseFloat(product.associatedModel.sales_item_price?.Price);
        }

        price = price + price * percentage;
      }
    }

    return price;
  };

  const { t } = useTranslation();

  return (
    <>
      {cartList.length === 0 ? (
        <div className={styles.emptyCart}>
          <p>{t("Your cart is empty!")}</p>
        </div>
      ) : (
        <>
          <div className={styles.bg}>
            <h5>{t("Shopping Cart")}</h5>
          </div>
          <Container>
            <div className={styles.breadcrumb}>
              <div role="presentation" onClick={handleClick}>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<NavigateNext fontSize="small" />}
                  sx={{ p: "14px 10px" }}
                >
                  <BCLink
                    className={styles.brdHov}
                    underline="none"
                    color="inherit"
                    href="/"
                    fontSize={"14px"}
                  >
                    {t("Home")}
                  </BCLink>
                  <BCLink
                    underline="none"
                    color="text.primary"
                    href="/cart"
                    aria-current="page"
                    fontSize={"14px"}
                  >
                    {t("Shopping Cart")}
                  </BCLink>
                </Breadcrumbs>
              </div>
            </div>
          </Container>
          <hr
            style={{
              margin: "0 0 40px",
              border: "none",
              borderBottom: "1px solid #ebebeb",
            }}
          />
          <Container sx={{ pb: "50px" }}>
            <AddressSelection
              ordered={addresses.ordered}
              invoice={addresses.invoice}
              delivery={addresses.delivery}
              contacts={contacts}
              accounts={accounts}
              refreshAddresses={refreshAddresses}
              refreshAccountsAndContacts={refreshAccountsAndContacts}
              setInputValue={setInputValue}
              inputValue={inputValue}
              initialInputState={initialInputState}
            />
            <Grid container spacing={2}>
              <Grid item lg={8} xs={12} sx={{ paddingLeft: "0px!important" }}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>{t("Product")}</th>
                      <th>{t("Price")}</th>
                      <th>{t("Quantity")}</th>
                      <th>{t("Total")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartList.map((val, idx) => (
                      <tr key={val.id}>
                        <td style={{ width: "50%" }}>
                          <div className={styles.product}>
                            {/* <div className={styles.imgDiv}>
                              <img
                                src={cartList[idx].associatedModel.PictureUrl}
                                alt="product_photo"
                                className={styles.imgCard}
                              />
                            </div> */}
                            <Link to={`/products/${cartList[idx].id}`} style={{ whiteSpace: "unset" }}>
                              {cartList[idx].name} <br />
                              {cartList[idx].associatedModel.Description}
                            </Link>
                          </div>
                        </td>
                        <td>
                          <h4 className={styles.price}>
                            {calculateSpecialPrice(
                              cartList[idx].associatedModel
                            ) != null &&
                              cartList[idx].associatedModel.sales_item_price
                                .Price !==
                              calculateSpecialPrice(
                                cartList[idx].associatedModel
                              ) ? (
                              <>
                                <strike>
                                  {numberFormatter.format(
                                    getPrice(cartList[idx], "general")
                                  )}
                                </strike>{" "}
                                {numberFormatter.format(
                                  getPrice(cartList[idx], "special")
                                )}
                              </>
                            ) : (
                              numberFormatter.format(
                                getPrice(cartList[idx], "general")
                              )
                            )}
                          </h4>
                        </td>
                        <td style={{ height: "100px" }}>
                          <Box>
                            <Quantity
                              id={cartList[idx].id}
                              sku={cartList[idx].associatedModel.Code}
                              isCartPage={true}
                            />
                          </Box>
                        </td>
                        <td
                          className={styles.totalPrice}
                          style={{ textAlign: "center" }}
                        >
                          {calculateSpecialPrice(
                            cartList[idx].associatedModel
                          ) != null &&
                            cartList[idx].associatedModel.sales_item_price
                              .Price !==
                            calculateSpecialPrice(
                              cartList[idx].associatedModel
                            ) ? (
                            <>
                              <strike>
                                {numberFormatter.format(
                                  getPrice(cartList[idx], "general") *
                                  cartList[idx].quantity
                                )}
                              </strike>{" "}
                              {numberFormatter.format(
                                getPrice(cartList[idx], "special") *
                                cartList[idx].quantity
                              )}
                            </>
                          ) : (
                            numberFormatter.format(
                              getPrice(cartList[idx], "general") *
                              cartList[idx].quantity
                            )
                          )}
                        </td>
                        <td>
                          <div
                            className={styles.deleteBtn}
                            onClick={deleteFromCart}
                            id={cartList[idx].id}
                          >
                            <Close />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Grid>
              <Grid item lg={4} xs={12}>
                <div className={styles.checkOutProcess}>
                  <div className={styles.cartDetail}>
                    <h5>{t("Cart Total")}</h5>
                    <p>
                      {t("Subtotal")}:{" "}
                      <span>{numberFormatter.format(subTotal() - vat())}</span>
                    </p>
                    {discount() > 0 && (
                      <p>
                        {t("Discount")}:{" "}
                        <span>{numberFormatter.format(discount())}</span>
                      </p>
                    )}
                    <p>
                      {t("BTW")}: <span>{numberFormatter.format(vat())}</span>
                    </p>
                    {/* <p>
                      Shipping Price:{" "}
                      <span>
                        {total() > 150 ? (
                          "Free"
                        ) : (
                          <small>Will be calculated later</small>
                        )}
                      </span>
                    </p> */}
                  </div>

                  <div
                    className={styles.cartDetail}
                    style={{ marginTop: 18, marginBottom: 2 }}
                  >
                    <h5>{t("Payment Method")}</h5>
                    <p>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox defaultChecked disabled />}
                          label={t("Pay by invoice")}
                        />
                      </FormGroup>
                    </p>
                  </div>
                  <div className={styles.end}>
                    <p>
                      {t("Total")}
                      <span>{numberFormatter.format(total())}</span>
                    </p>

                    <button
                      className={`${styles.checkOutBtn}`}
                      onClick={checkOut}
                      disabled={isInApi}
                    >
                      {isInApi ? (
                        <Bars height="20" width="100%" color="#cc9966" />
                      ) : (
                        t("Checkout")
                      )}
                    </button>
                  </div>
                </div>
                <Link to="/" className={styles.continueBtn}>
                  {t("CONTINUE SHOPPING")} <TbRefresh />
                </Link>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
      <hr
        style={{
          display: "block",
          height: "1px",
          border: "0",
          borderTop: "1px solid #ccc",
          margin: "0",
          padding: "0",
        }}
      />
    </>
  );
};

export default Cart;
