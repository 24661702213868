import React from "react";
import { Grid, Link } from "@mui/material";
import LinesTable from "./LinesTable";
import moment from "moment/min/moment-with-locales";
import axios from "../../axios";
import download from "downloadjs";
import { useTranslation } from "react-i18next";


export default function InvoiceDetails(props) {
  const downloadPdf = () => {
    axios
      .get("/my-account/invoices/" + props.data.InvoiceID + "/download", {
        responseType: "blob",
      })
      .then((response) => {
        const content = response.headers["content-type"];
        download(response.data, `Invoice_${props.data.InvoiceNumber}.pdf`, content);
      });
  };
  const { t } = useTranslation();

  return (
    <div>
      <Grid container>
        <Grid item xs sx={{ p: 2 }}>
          <b>{t("Ordered By Account")}</b>
          <div>{props.data.ordered_by_account?.Name}</div>
          <div>{props.data.ordered_by_account?.Email}</div>
          <br />
          {props.data.ordered_by_contact != null && (
            <>
              <b>{t("Ordered By Contact")}</b>
              <div>{props.data.ordered_by_contact.FullName}</div>
              <div style={{ whiteSpace: "nowrap" }}>
                {props.data.ordered_by_contact.Email}
              </div>
              <br />
            </>
          )}

          <div>
            <b>{t("Address")}</b> <br />
            {props.data.ordered_by_account?.visit_address?.AddressLine1}
            <br />
            {props.data.ordered_by_account?.visit_address?.AddressLine2}
            <br />
            {props.data.ordered_by_account?.visit_address?.AddressLine3}
            <br />
            {props.data.ordered_by_account?.visit_address?.Postcode}
          </div>
          <div>
            {props.data.ordered_by_account?.visit_address?.City}{" "}
            {props.data.ordered_by_account?.visit_address?.Country}
          </div>
        </Grid>

        <Grid item xs sx={{ p: 2 }}>
          <b>{t("Delivery To Account")}</b>
          <div>{props.data.delivery_to_account.Name}</div>
          <div>{props.data.delivery_to_account.Email}</div>
          <br />
          {props.data.delivery_to_contact != null && (
            <>
              <b>{t("Delivery To Contact")}</b>
              <div>{props.data.delivery_to_contact.FullName}</div>
              <div style={{ whiteSpace: "nowrap" }}>
                {props.data.delivery_to_contact.Email}
              </div>
              <br />
            </>
          )}

          <div>
            <b>{t("Address")}</b> <br />
            {props.data.delivery_to_account?.delivery_address?.AddressLine1}
            <br />
            {props.data.delivery_to_account?.delivery_address?.AddressLine2}
            <br />
            {props.data.delivery_to_account?.delivery_address?.AddressLine3}
            <br />
            {props.data.delivery_to_account?.delivery_address?.Postcode}
          </div>
          <div>
            {props.data.delivery_to_account?.delivery_address?.City}{" "}
            {props.data.delivery_to_account?.delivery_address?.Country}
          </div>
        </Grid>

        <Grid item xs sx={{ p: 2 }}>
          <b>{t("Invoice To Account")}</b>
          <div>{props.data.invoice_to_account.Name}</div>
          <div style={{ whiteSpace: "nowrap" }}>
            {props.data.invoice_to_account.Email}
          </div>
          <br />
          {props.data.invoice_to_contact != null && (
            <>
              <b>{t("Invoice To Contact")}</b>
              <div>{props.data.invoice_to_contact.FullName}</div>
              <div>{props.data.invoice_to_contact.Email}</div>
              <br />
            </>
          )}

          <div>
            <b>{t("Address")}</b> <br />
            {props.data.invoice_to_account?.invoice_address?.AddressLine1}
            <br />
            {props.data.invoice_to_account?.invoice_address?.AddressLine2}
            <br />
            {props.data.invoice_to_account?.invoice_address?.AddressLine3}
            <br />
            {props.data.invoice_to_account?.invoice_address?.Postcode}
          </div>
          <div>
            {props.data.invoice_to_account?.invoice_address?.City}{" "}
            {props.data.invoice_to_account?.invoice_address?.Country}
          </div>
        </Grid>

        <Grid container>
          <Grid item xs sx={{ p: 2 }}>
            <b>{t("Description")}</b>
            <div>{props.data.Description}</div>
          </Grid>
          <Grid item xs sx={{ p: 2 }}>
            <b>{t("Reference")}</b>
            <div>{props.data.YourRef}</div>
          </Grid>
          <Grid item xs sx={{ p: 2 }}>
            <b>{t("Order")}</b>
            <div>
              {props.data.order == null ? (
                <>{t("Order does not exists.")}</>
              ) : (
                <Link href={"/my-account/orders/" + props.data.order.OrderID}>
                  {t("Order")} #{props.data.order.OrderNumber}
                </Link>
              )}
            </div>
          </Grid>
          {/* <Grid item xs sx={{ p: 2 }}>
            <b>Status</b>
            <div>{props.data.Status}</div>
          </Grid> */}
          <Grid item xs sx={{ p: 2 }}>
            <b>{t("Document")}</b>
            <div>
              {props.data.HasInvoicePdf ? (
                <Link component="button" onClick={downloadPdf}>
                  {t("Download Invoice")}
                </Link>
              ) : (
                <>{t("There is no document")}</>
              )}
            </div>
          </Grid>
          <Grid item xs sx={{ p: 2 }}>
            <b>{t("Invoice Date")}</b>
            <div>{moment(props.data.InvoiceDate).locale('nl').format("LL")}</div>
            <b>{t("Due Date")}</b>
            <div>{moment(props.data.DueDate).locale('nl').format("LL")}</div>
          </Grid>
        </Grid>
        <Grid container>
          <LinesTable data={props.data.lines} total={props.data.AmountDC} />
        </Grid>
      </Grid>
    </div>
  );
}
