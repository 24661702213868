/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Container, Grid, Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Container sx={{ marginTop: 4, marginBottom: 2, width: "100%" }}>
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1>{t("Error 404")}</h1>
          <p>{t("We are sorry, the page you've requested is not available.")}</p>
          <Link
            component="button"
            variant="outlined"
            href={"/"}
            sx={{ marginTop: 2 }}
          >
            {t("Back to home")}
          </Link>
        </Box>
      </Grid>
    </Container>
  );
};

export default React.memo(NotFound);
