import React from "react";
import { FormControl, FormLabel, Grid, InputLabel, MenuItem, Select, } from "@mui/material";
import Auth from "../../../Auth";
import { useTranslation } from "react-i18next";

export default function Accounts({
    accounts,
    inputValue,
    handleChange,
    handleOpenAccountDialog,
}) {
    const findMainContact = (id) => {
        if (Auth.getUser().ID === id) {
            return Auth.getUser().MainContact;
        }

        return accounts.find((row) => row.ID === id)?.MainContact;
    };

    const { t } = useTranslation();

    return (
        <Grid
            container
            item
            spacing={1}
            sx={{ paddingLeft: "0px !important", paddingRight: "0px !important" }}
        >
            <Grid item xs={4}>
                <FormControl fullWidth spacing={1}>
                    <FormLabel>{t("Ordered By Account")}</FormLabel>
                    <FormControl fullWidth sx={{ marginTop: 1 }}>
                        <InputLabel id="ordered-by-contact-label">{t("Account")}</InputLabel>
                        <Select
                            labelId="ordered-by-contact-label"
                            label={t("Select")}
                            size="sm"
                            disabled
                            fullWidth
                            value={Auth.getUser().ID}
                        >
                            <MenuItem value={Auth.getUser().ID}>
                                {Auth.getUser().Name}
                            </MenuItem>
                            {accounts.map((account) => {
                                return (
                                    <MenuItem value={account.ID} key={account.ID}>
                                        {account.Name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel>{t("Delivery Account")}</FormLabel>
                    <FormControl fullWidth sx={{ marginTop: 1 }}>
                        <InputLabel id="delivery-to-contact-label">{t("Account")}</InputLabel>
                        <Select
                            labelId="delivery-to-contact-label"
                            label={t("Select")}
                            size="sm"
                            fullWidth
                            value={inputValue.main.deliver_to_account}
                            onChange={(e) => {
                                handleChange(
                                    {
                                        target: {
                                            id: "deliver_to_account",
                                            value: e.target.value,
                                            contact: findMainContact(e.target.value),
                                        },
                                    },
                                    "main"
                                );
                            }}
                        >
                            <MenuItem value={Auth.getUser().ID}>
                                {Auth.getUser().Name}
                            </MenuItem>
                            {accounts.map((account) => {
                                return (
                                    <MenuItem key={account.ID} id={account.ID} value={account.ID}>
                                        {account.Name}
                                        {/* &nbsp;
                    <Link
                      onClick={handleOpenAccountDialog}
                      id={account.ID}
                      data-row={JSON.stringify(account)}
                      account-type="delivery_account"
                    >
                      Edit
                    </Link> */}
                                    </MenuItem>
                                );
                            })}
                            <MenuItem
                                onClick={handleOpenAccountDialog}
                                account-type="delivery_account"
                            >
                                {t("Add new")}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel>{t("Invoice To Account")}</FormLabel>
                    <FormControl fullWidth sx={{ marginTop: 1 }}>
                        <InputLabel id="invoice-to-contact-label">{t("Account")}</InputLabel>
                        <Select
                            labelId="invoice-to-contact-label"
                            label={t("Select")}
                            size="sm"
                            fullWidth
                            onChange={(e) => {
                                handleChange(
                                    {
                                        target: {
                                            id: "invoice_to_account",
                                            value: e.target.value,
                                            contact: findMainContact(e.target.value),
                                        },
                                    },
                                    "main"
                                );
                            }}
                            value={inputValue.main.invoice_to_account}
                        >
                            <MenuItem
                                value={Auth.getUser().ID}
                                data={JSON.stringify(Auth.getUser())}
                            >
                                {Auth.getUser().Name}
                            </MenuItem>
                            {accounts.map((account) => {
                                return (
                                    <MenuItem
                                        key={account.ID}
                                        data={JSON.stringify(account)}
                                        value={account.ID}
                                    >
                                        {account.Name}&nbsp;
                                        {/* <Link
                      onClick={handleOpenAccountDialog}
                      id={account.ID}
                      data-row={JSON.stringify(account)}
                      account-type="invoice_account"
                    >
                      Edit
                    </Link> */}
                                    </MenuItem>
                                );
                            })}
                            <MenuItem
                                onClick={handleOpenAccountDialog}
                                account-type="invoice_account"
                            >
                                {t("Add new")}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </FormControl>
            </Grid>
        </Grid>
    );
}
