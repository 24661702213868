import React from "react";
import styles from "./Styles.module.scss";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ThanksForOrder() {
  const urlParams = useParams();
  const { t } = useTranslation();
  const orderNumber = urlParams.order_number;

  return (
    <div>
      <div className={styles.emptyCart}>
        <p style={{ paddingBottom: 12 }}>{t("Thanks for your order!")}</p>
        <span>
          {t("Your order number is")} <i>#{orderNumber}</i>
        </span>
      </div>
    </div>
  );
}
