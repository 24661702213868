import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import moment from "moment/min/moment-with-locales";
import { numberFormatter } from "../../formatter";
import MiniLoading from "../../components/MiniLoading";
import styles from "./Styles.module.scss";
import { useTranslation } from "react-i18next";


export default function InvoicesTable(props) {
  const NoRecords = () => (
    <TableRow>
      <TableCell colSpan={9} sx={{ textAlign: "center" }}>
        {t("There are no records")}
      </TableCell>
    </TableRow>
  );
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <span className={styles.tableThead}>{t("Invoice Number")}</span>
            </TableCell>
            <TableCell>
              <span className={styles.tableThead}>{t("Order Number")}</span>
            </TableCell>
            <TableCell>
              <span className={styles.tableThead}>{t("Due Date")}</span>
            </TableCell>
            <TableCell>
              <span className={styles.tableThead}>{t("Reference")}</span>
            </TableCell>
            {/* <TableCell><span className={styles.tableThead}>Status</span></TableCell> */}
            <TableCell>
              <span className={styles.tableThead}>{t("Invoice Total")}</span>
            </TableCell>
            {/* <TableCell><span className={styles.tableThead}>Paid</span></TableCell>
            <TableCell><span className={styles.tableThead}>Balance</span></TableCell> */}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data == null ? (
            <TableRow>
              <TableCell colSpan={9}>
                <MiniLoading />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {props.data.data.length === 0 && <NoRecords />}
              {props.data.data?.map((row) => (
                <TableRow
                  key={row.ID}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.InvoiceNumber}
                  </TableCell>
                  <TableCell>{row.OrderNumber}</TableCell>
                  <TableCell>
                    {moment(row.DueDate).locale('nl').format("LLL")}
                  </TableCell>
                  <TableCell>{row.YourRef}</TableCell>
                  {/* <TableCell>{row.Status}</TableCell> */}
                  <TableCell>{numberFormatter.format(row.AmountDC)}</TableCell>
                  {/* <TableCell>{numberFormatter.format(row.Paid)}</TableCell>
                  <TableCell>{numberFormatter.format(row.Balance)}</TableCell> */}
                  <TableCell>
                    <Link href={"/my-account/invoices/" + row.InvoiceID}>
                      {t("Details")}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
