import { Box, Checkbox, FormControlLabel, Link } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { VscArrowSmallRight } from "react-icons/vsc";
import styles from "./Styles.module.scss";
import { Bars } from "react-loader-spinner";
import { DataContext } from "../../App";
import { validateForgotPassword, validateSignIn } from "../Handle";
import axios from "../../axios";
import Auth from "../../Auth";
import { useTranslation } from "react-i18next";

const Login = (props) => {
  const { setAuth } = useContext(DataContext);
  const { setSnackbar } = useContext(DataContext);
  const [inputValue, setInputValue] = useState({
    code:
      localStorage.getItem("remember_me") !== "" &&
        localStorage.getItem("remember_me") != null
        ? localStorage.getItem("remember_me")
        : "",
    password: "",
    remember_me:
      localStorage.getItem("remember_me") !== "" &&
      localStorage.getItem("remember_me") !== null,
  });
  const { t } = useTranslation();

  const [loginButton, setLoginButton] = useState({
    children: (
      <>
        {t("LOG IN")} <VscArrowSmallRight />
      </>
    ),
    status: false,
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const handleChange = (event) => {
    setInputValue((prevState) => ({
      ...prevState,
      [event.target.id]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
  };

  useEffect(() => {
    setErrors(validateSignIn(inputValue, t));
  }, [inputValue, touched]);

  const focusHandler = (event) => {
    setTouched({ ...touched, [event.target.id]: true });
  };

  const loginUser = async () => {
    if (!Object.keys(errors).length) {
      setLoginButton({
        children: <Bars height="20" width="80" color="var(--primary)" />,
        status: true,
      });

      axios
        .post("/login", {
          code: inputValue.code,
          password: inputValue.password,
        })
        .then((response) => {
          if (inputValue.remember_me) {
            localStorage.setItem("remember_me", response.data.user.Code.trim());
          } else {
            localStorage.removeItem("remember_me");
          }

          localStorage.setItem("currentStoreTheme", JSON.stringify(response.data.store_settings));

          setAuth({ currentUser: response.data.user });
          Auth.setUser(response.data.user);
          Auth.setToken(response.data.token.plainTextToken);

          window.location.href = "/";
        })
        .catch(({ response }) => {
          setSnackbar({
            message: t(response.data.message),
            severity: "error",
            open: true,
          });

          setLoginButton({
            children: (
              <>
                {t("LOG IN")} <VscArrowSmallRight />
              </>
            ),
            status: false,
          });
        });
    } else {
      setTouched({
        code: true,
        password: true,
        remember_me: true,
      });
    }
  };

  return (
    <>
      <div className={styles.inputs} style={{ marginBottom: "13px" }}>
        <div>
          <label htmlFor="code">{t("Account Code")} *</label>
        </div>
        <input
          defaultValue={inputValue.code}
          onChange={handleChange}
          onFocus={focusHandler}
          type="text"
          id="code"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              loginUser();
            }
          }}
        />
        <div>{errors.code && touched.code && <p>{errors.code}</p>}</div>
      </div>
      <div className={styles.inputs} style={{ marginBottom: "13px" }}>
        <div>
          <label htmlFor="password">{t("Password")} *</label>
        </div>
        <input
          defaultValue={inputValue.password}
          onChange={handleChange}
          onFocus={focusHandler}
          type="password"
          id="password"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              loginUser();
            }
          }}
        />
        <div>
          {errors.password && touched.password && <p>{errors.password}</p>}
        </div>
        <FormControlLabel
          color="apimasters"
          control={
            <Checkbox
              color="apimasters"
              id="remember_me"
              onChange={handleChange}
              checked={inputValue.remember_me}
            />
          }
          label={t("Remember me")}
        />
      </div>

      <div className={styles.login}>
        <button
          disabled={loginButton.status}
          className={loginButton.status ? styles.none : ""}
          onClick={loginUser}
        >
          {loginButton.children}
        </button>
      </div>
    </>
  );
};

const ForgotPassword = (props) => {
  const { setSnackbar } = useContext(DataContext);
  const [inputValue, setInputValue] = useState({
    code: "",
  });
  const { t } = useTranslation();

  const [loginButton, setLoginButton] = useState({
    children: (
      <>
        {t("SEND EMAIL")} <VscArrowSmallRight />
      </>
    ),
    status: false,
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const handleChange = (event) => {
    setInputValue((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value,
    }));
  };

  useEffect(() => {
    setErrors(validateForgotPassword(inputValue, t));
  }, [inputValue, touched]);

  const focusHandler = (event) => {
    setTouched({ ...touched, [event.target.id]: true });
  };

  const sendPasswordResetLink = async () => {
    if (!Object.keys(errors).length) {
      setLoginButton({
        children: <Bars height="20" width="80" color="var(--primary)" />,
        status: true,
      });

      axios
        .post("/forgot-password", {
          code: inputValue.code,
        })
        .then((response) => {
          setSnackbar({
            message: t("Password reset link successfully sent."),
            severity: "success",
            open: true,
          });

          setLoginButton({
            children: (
              <>
                {t("SEND EMAIL")} <VscArrowSmallRight />
              </>
            ),
            status: false,
          });
        })
        .catch(({ response }) => {
          setSnackbar({
            message: t(response.data.message),
            severity: "error",
            open: true,
          });

          setLoginButton({
            children: (
              <>
                {t("SEND EMAIL")} <VscArrowSmallRight />
              </>
            ),
            status: false,
          });
        });
    } else {
      setTouched({
        code: true,
      });
    }
  };

  return (
    <>
      <div className={styles.inputs} style={{ marginBottom: "13px" }}>
        <div>
          <label htmlFor="code">{t("Account Code")} *</label>
        </div>
        <input
          defaultValue={inputValue.code}
          onChange={handleChange}
          onFocus={focusHandler}
          type="text"
          id="code"
        />
        <div>{errors.code && touched.code && <p>{errors.code}</p>}</div>
      </div>
      <div className={styles.login}>
        <button
          disabled={loginButton.status}
          className={loginButton.status ? styles.none : ""}
          onClick={sendPasswordResetLink}
        >
          {loginButton.children}
        </button>
      </div>
    </>
  );
};

const SignIn = (props) => {
  const [loginVisiblity, setShowLogin] = useState(true);
  const { t } = useTranslation();

  return (
    <Box>
      {loginVisiblity ? (
        <Login onSuccess={props.onSuccess} />
      ) : (
        <ForgotPassword />
      )}

      <hr style={{ margin: "2rem 0" }} />
      <div
        className={styles.bottom}
        style={{
          textAlign: "center",
        }}
      >
        <Link
          component={"button"}
          onClick={() => {
            setShowLogin((prevState) => !prevState);
          }}
        >
          {loginVisiblity ? t("Forgot password?") : t("Back to login")}
        </Link>
      </div>
    </Box>
  );
};

export default SignIn;
